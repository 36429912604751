import React from 'react';
import { styled } from '@mui/material';
import { Trans } from '@lingui/react';
import { typography, colors, layout } from 'portal-design-system';

const Label = styled('div')({
  fontSize: typography.fontSize.base,
  color: colors.greyish3,
});
const Value = styled('div')({
  fontSize: typography.fontSize.lg,
  marginBottom: layout.spacing(1),
});

type Props = {
  label: string;
  value?: string;
};

function MenuEntry({ label, value }: Props) {
  if (!value) {
    return null;
  }
  return (
    <>
      <Label>
        <Trans id={`accountMenu.${label}`} />
      </Label>
      <Value>{value}</Value>
    </>
  );
}

export default MenuEntry;

import React from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material';
// /!\ @mui/styles IS DEPRECATED
import { makeStyles, createStyles } from '@mui/styles';

const useStyle = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        justifyContent: 'start',
        width: '160px',
        borderTopStyle: 'solid',
        borderTopWidth: '0.2rem',
        borderTopColor: theme.palette.grey[400],
      },
      completed: {
        borderTopColor: theme.palette.grey[800],
      },
      active: {
        borderTopColor: theme.palette.secondary.main,
      },
    }),
  { name: 'AxtStepStyle' }
);

export interface StepProps extends React.HTMLAttributes<HTMLDivElement> {
  active?: boolean;
  children?: React.ReactElement[];
  completed?: boolean;
  disabled?: boolean;
  index?: number;
  last?: boolean;
  error?: boolean;
}

function Step(props: StepProps, ref: React.Ref<HTMLDivElement>) {
  const {
    active = false,
    completed = false,
    disabled = false,
    last,
    error,
    children,
    ...other
  } = props;

  const classes = useStyle();
  return (
    <div
      className={clsx(classes.root, {
        [classes.completed]: completed,
        [classes.active]: active,
      })}
      ref={ref}
      {...other}
    >
      {React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        return React.cloneElement(child, {
          active,
          completed,
          disabled,
          last,
          error,
          ...child.props,
        });
      })}
    </div>
  );
}

export default React.forwardRef(Step);

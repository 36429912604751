type ItemKey = 'language';

class Store {
  private getItem(key: ItemKey): string | null {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      return null;
    }
  }

  private setItem(key: ItemKey, value: string) {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      console.warn('localStorage unavailable.');
    }
  }

  public getLanguage(): string | null {
    return this.getItem('language');
  }

  public setLanguage(lang: string) {
    return this.setItem('language', lang);
  }
}

export default new Store();

import React from 'react';
import clsx from 'clsx';
import { Paper, IconButton, Theme } from '@mui/material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
// /!\ @mui/styles IS DEPRECATED
import { makeStyles, createStyles } from '@mui/styles';

// Styles
const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        padding: '6px 16px',
        backgroundColor: theme.palette.grey[100],
      },
      success: {
        borderLeft: `2px solid ${theme.palette.success.main}`,
        '& $icon': {
          color: theme.palette.success.main,
        },
      },
      info: {
        borderLeft: `2px solid ${theme.palette.info.main}`,
        '& $icon': {
          color: theme.palette.info.main,
        },
      },
      warning: {
        borderLeft: `2px solid ${theme.palette.warning.main}`,
        '& $icon': {
          color: theme.palette.warning.main,
        },
      },
      error: {
        borderLeft: `2px solid ${theme.palette.error.main}`,
        '& $icon': {
          color: theme.palette.error.main,
        },
      },
      icon: {
        marginRight: 12,
        padding: '7px 0',
        display: 'flex',
        fontSize: 22,
        opacity: 0.9,
      },
      message: {
        padding: '8px 0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
      action: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        paddingLeft: 16,
        marginRight: -8,
      },
    }),
  { name: 'AxtNotificationStyle' }
);

// Icon mapping
const defaultIconMapping = {
  success: <CheckCircleOutlinedIcon fontSize="inherit" />,
  warning: <ReportProblemOutlinedIcon fontSize="inherit" />,
  error: <ErrorOutlineIcon fontSize="inherit" />,
  info: <InfoOutlinedIcon fontSize="inherit" />,
};

// Type
export type Status = 'success' | 'info' | 'warning' | 'error';

export type AxtNotificationProps = React.HTMLAttributes<HTMLDivElement> & {
  elevation: number;
  action?: React.ReactNode;
  status: Status;
  icon?: React.ReactNode | false;
  iconMapping?: Partial<Record<Status, React.ReactNode>>;
  onClose?: (event: React.SyntheticEvent) => void;
  children?: React.ReactNode;
};

// Component
const AxtNotification = React.forwardRef(function AxtNotification(
  props: AxtNotificationProps,
  ref: React.Ref<HTMLDivElement>
) {
  const classes = useStyles();
  const {
    elevation,
    action,
    status,
    icon,
    iconMapping = defaultIconMapping,
    onClose,
    children,
    ...other
  } = props;
  return (
    <Paper
      square
      elevation={elevation}
      className={clsx(classes.root, {
        [classes.success]: status === 'success',
        [classes.info]: status === 'info',
        [classes.warning]: status === 'warning',
        [classes.error]: status === 'error',
      })}
      ref={ref}
      {...other}
    >
      {icon !== false ? (
        <div className={classes.icon}>{icon || iconMapping[status]}</div>
      ) : null}
      <div className={classes.message}>{children}</div>
      {action == null && onClose ? (
        <div className={classes.action}>
          <IconButton size="small" aria-label="close" onClick={onClose}>
            <CloseOutlinedIcon fontSize="small" />
          </IconButton>
        </div>
      ) : null}
    </Paper>
  );
});

export default AxtNotification;

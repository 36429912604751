import { init, captureException } from '@sentry/browser';
import config from '../config';

let isSentryConfigured = false;

export function configureSentry() {
  if (config.sentryUrl) {
    try {
      init({
        dsn: config.sentryUrl,
      });
      isSentryConfigured = true;
    } catch (e) {
      console.error('Error while configuring sentry: ', e);
    }
  } else {
    console.warn('Sentry URL is missing.');
  }
}

export function handleError(error: Error, appName: string) {
  if (isSentryConfigured) {
    // For some mysterious reason, without this line the sentry browser client will not send the tag along with the exception
    error.message = `[${appName}] ${error.message}`; // eslint-disable-line no-param-reassign

    captureException(error, {
      tags: {
        application: appName,
      },
    });
  } else {
    console.warn('Sentry is not configured.');
  }
  console.error(error);
}

import React, {
  useCallback,
  useState,
  MouseEvent,
  KeyboardEvent,
  useMemo,
} from 'react';
import {
  styled,
  Autocomplete,
  TextField,
  Popper,
  InputBase,
  ClickAwayListener,
  AutocompleteRenderInputParams,
  createFilterOptions,
} from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { ui } from '../../design-tokens';
import MultiSelectOption from './MultiSelectOption';

export const OPTION_SELECT_ALL = 'OPTION_SELECT_ALL';

function isOptionSelectAll(option: any) {
  return typeof option === 'string' && option === OPTION_SELECT_ALL;
}

// Trick copied from the MUI example (https://codesandbox.io/s/gnu71s?file=/demo.tsx)
// disable portal in order to render the dropdown list as a child of the popper element
// FYI:
// - Using Popper + ClickAwayListener instead of Popover because Popover + Autocomplete = placement issues (MUI issue)
// - clickaway will break if it has more than one child (hence the div element)
type PopperComponentProps = {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
};

function PopperComponent(props: PopperComponentProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { disablePortal, anchorEl, open, ...other } = props;
  return <div {...other} />;
}

/********************** */
/***** STYLED COMP **** */
/********************** */

const StyledInput = styled(InputBase)(() => ({
  padding: 10,
  width: '100%',
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.modal,
  backgroundColor: theme.palette.common.white,
  borderRadius: ui.radius.md,
  boxShadow: ui.elevation[4],
}));

/********************** */
/***** MULTISELECT **** */
/********************** */

export type Props<T> = {
  options: T[]; // Dropdown options
  getOptionLabel: (option: T) => string; // Label to display for each option, also used to return the React key for list elements
  values: T[]; // Selected values
  setValues: (values: T[]) => void; // Callback to select values
  withSelectAll?: boolean; // Should include a "select all" option at the top of the list
  allSelectedLabel?: string; // Label to display in the Text field when all values or selected (instead of a concatenation of option labels)
  placeHolder?: string; // place hoder for the Text field
  filterPlaceholder?: string; // placeholder for the filter field
  emptyListPlaceholder?: string; // placeholder in case options list is empty
  width?: number; // Text field width
  popupWidth?: number; // Dropdown width. If not provided, it's the same as the Text field's width
  getOptionDisabled?: (option: T) => boolean;
  disabledSuffix?: string;
};

const MultiSelect = <T extends unknown>({
  options,
  getOptionLabel,
  values,
  setValues,
  withSelectAll,
  allSelectedLabel,
  placeHolder,
  filterPlaceholder,
  emptyListPlaceholder,
  width = 400,
  popupWidth,
  getOptionDisabled,
  disabledSuffix,
}: Props<T>) => {
  // Status of the "SELECT ALL" option
  const allSelected = useMemo(() => {
    // check if all options or all non-disabled options are selected
    if (getOptionDisabled) {
      return (
        values.length === options.filter((o) => !getOptionDisabled(o)).length
      );
    }
    return values.length === options.length;
  }, [getOptionDisabled, values.length, options]);

  /********************** */
  /***** STATE       **** */
  /********************** */
  // popper anchor
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  /********************** */
  /***** HANDLERS    **** */
  /********************** */

  const handleOpen = useCallback((event: MouseEvent<HTMLDivElement>) => {
    // open popover
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  }, [anchorEl]);

  const handleChange = useCallback(
    (event, newValue, reason) => {
      if (
        event.type === 'keydown' &&
        (event as KeyboardEvent).key === 'Backspace' &&
        reason === 'removeOption'
      ) {
        return;
      }
      if (newValue.find((value: any) => value === OPTION_SELECT_ALL)) {
        // TOGGLE SELECT ALL
        if (allSelected) {
          // unselect all
          return setValues([]);
        }
        // select all but disabled values
        if (getOptionDisabled) {
          return setValues([...options.filter((o) => !getOptionDisabled(o))]);
        }
        return setValues([...options]);
      }
      return setValues(newValue);
    },
    [allSelected, options, setValues, getOptionDisabled]
  );

  const handleAutocompleteClose = useCallback(
    (_event, reason) => {
      if (reason === 'escape') {
        handleClose();
      }
    },
    [handleClose]
  );

  const getOptionLabelOrAll = useCallback(
    (option: T | string) => {
      if (typeof option === 'string') {
        if (option === OPTION_SELECT_ALL) {
          return OPTION_SELECT_ALL;
        }
        return option;
      }
      return getOptionLabel(option);
    },
    [getOptionLabel]
  );

  const getOptionDisabledOrAll = useCallback(
    (option: string | T) => {
      if (getOptionDisabled) {
        if (typeof option === 'string' && option === OPTION_SELECT_ALL) {
          // Select all is not disabled
          return false;
        }
        // For other options, rely on the function provided by the user
        return getOptionDisabled(option as T);
      }
      return false;
    },
    [getOptionDisabled]
  );

  /********************** */
  /***** RENDER FUNC **** */
  /********************** */

  // Render the selected values in the Text Field
  const renderValues = useCallback(
    (values: T[]): string => {
      if (allSelectedLabel && allSelected) {
        return allSelectedLabel;
      }
      return values.map(getOptionLabel).join(', ');
    },
    [allSelected, allSelectedLabel, getOptionLabel]
  );

  // Render a single option for the dropdown
  const renderOption = useCallback(
    (props, option, { selected }) => {
      const label = getOptionLabelOrAll(option);
      const isSelectAll = isOptionSelectAll(option);
      return (
        <MultiSelectOption
          label={label}
          isSelectAll={isSelectAll}
          selected={isSelectAll ? allSelected : selected}
          disabledSuffix={disabledSuffix}
          {...props}
        />
      );
    },
    [allSelected, getOptionLabelOrAll, disabledSuffix]
  );

  // Render the dropdown filter
  const renderFilter = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <StyledInput
        ref={params.InputProps.ref}
        inputProps={params.inputProps}
        autoFocus
        placeholder={filterPlaceholder}
        startAdornment={<SearchRoundedIcon sx={{ marginRight: '1rem' }} />}
      />
    ),
    [filterPlaceholder]
  );

  // Function that will "filter" the option list
  // Here it is use to add a "select all" option if this feature is activated
  const filter = createFilterOptions<T | string>();
  const filterOptions = useCallback(
    (options: (T | string)[], params: any) => {
      const filtered = filter(options, params);
      if (withSelectAll && !params.inputValue) {
        // add the option "select all" if it's enabled and if we are not currently filtering
        return [OPTION_SELECT_ALL, ...filtered] as (T | string)[];
      }
      return filtered;
    },
    [filter, withSelectAll]
  );

  return (
    <>
      <TextField
        value={renderValues(values)}
        onClick={handleOpen}
        placeholder={placeHolder}
        autoComplete="off"
        variant="outlined"
        size="small"
        sx={{
          width,
          '& .MuiInputBase-root': {
            cursor: 'pointer',
          },
          '& .MuiInputBase-root input': {
            cursor: 'pointer',
          },
        }}
        inputProps={{
          style: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        }}
        data-testid="MultiSelectTextField"
      />
      <StyledPopper
        open={!!anchorEl}
        anchorEl={anchorEl}
        placement="bottom-start"
        sx={{
          width: popupWidth || width,
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div>
            <Autocomplete
              open
              multiple
              size="small"
              PopperComponent={PopperComponent}
              options={options}
              noOptionsText={emptyListPlaceholder}
              getOptionLabel={getOptionLabelOrAll}
              renderOption={renderOption}
              renderInput={renderFilter}
              filterOptions={filterOptions}
              getOptionDisabled={getOptionDisabled && getOptionDisabledOrAll}
              value={values}
              onChange={handleChange}
              onClose={handleAutocompleteClose}
              disableCloseOnSelect
              sx={{
                width: '100%',
              }}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
};

export default MultiSelect;

import React from 'react';
import { Typography, Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

const useStyle = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
      },
      subtitle: {
        color: theme.palette.grey[600],
      },
    }),
  { name: 'AxtTitleStyle' }
);

export type AxtTitleProps = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
};

function AxtTitle({ title, subtitle }: AxtTitleProps) {
  const classes = useStyle();
  return (
    <div className={classes.root}>
      <Typography variant="h2" gutterBottom={true}>
        {title}
      </Typography>
      {subtitle && (
        <Typography
          className={classes.subtitle}
          component="p"
          variant="subtitle1"
        >
          {subtitle}
        </Typography>
      )}
    </div>
  );
}

export default AxtTitle;

import React, { useCallback, useState } from 'react';
import { useDebounce } from 'react-use';
import { Divider, Paper, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AxtSearchInput from './AxtSearchInput';
import AxtSearchMenu from './AxtSearchMenu';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '500px',
    minWidth: '80px',
    padding: theme.spacing(0.25, 0.5),
    marginBottom: theme.spacing(1),
  },
  divider: {
    height: '28px',
    margin: theme.spacing(0.5),
  },
}));

type AxtSearchBarProps = {
  name: string;
  value?: string;
  submitOnChange?: boolean;
  placeholder?: string;
  clearTooltipTextContent: JSX.Element | string;
  searchTooltipTextContent: JSX.Element | string;
  filterMenuLabel: JSX.Element | string;
  setFieldValue: (name: string, value?: string) => void;
  onSubmit: () => void;
  menuRef?: React.Ref<{
    close: () => void;
  } | null>;
  children?: React.ReactNode;
};

function AxtSearchBar({
  name,
  value,
  submitOnChange,
  placeholder,
  searchTooltipTextContent,
  clearTooltipTextContent,
  filterMenuLabel,
  setFieldValue,
  onSubmit,
  menuRef,
  children,
}: AxtSearchBarProps) {
  const classes = useStyles();
  const [internalValue, setInternalValue] = useState(value || '');
  const [isFirstMount, setIsFirstMount] = useState(true);

  useDebounce(
    () => {
      if (isFirstMount) {
        // useDebounce hook will always be triggered the first time
        // with the initial value of the internalValue variable.
        // So, we need to ignore this first call.
        setIsFirstMount(false);
      } else {
        setFieldValue(name, internalValue);
        if (submitOnChange) {
          onSubmit();
        }
      }
    },
    500,
    [
      name,
      internalValue,
      submitOnChange,
      setFieldValue,
      setIsFirstMount,
      onSubmit,
    ]
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInternalValue(e?.target?.value || '');
    },
    [setInternalValue]
  );

  const handleClear = useCallback(() => {
    setFieldValue(name, '');
    setInternalValue('');
    onSubmit();
  }, [name, setFieldValue, onSubmit]);

  return (
    <Paper component="form" square className={classes.root}>
      <AxtSearchInput
        fieldName={name}
        value={internalValue}
        placeholder={placeholder}
        searchTooltipTextContent={searchTooltipTextContent}
        clearTooltipTextContent={clearTooltipTextContent}
        onChange={handleChange}
        onClear={handleClear}
        onSubmit={onSubmit}
      />
      {children && (
        <>
          <Divider className={classes.divider} orientation="vertical" />
          <AxtSearchMenu menuRef={menuRef} filterMenuLabel={filterMenuLabel}>
            {children}
          </AxtSearchMenu>
        </>
      )}
    </Paper>
  );
}

export default AxtSearchBar;

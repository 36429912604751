import React from 'react';
import { Trans } from '@lingui/react';
import { styled, LinearProgress, Typography } from '@mui/material';

const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '40px',
});

const Container = styled('div')({
  width: '400px',
  '& div:first-of-type': {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '40px',
  },
});

const LazyLoadingFallback = () => {
  return (
    <Wrapper>
      <Container>
        <div>
          <Typography variant="h4" color="textSecondary" display="inline">
            <Trans id="global.loading" />
            ...
          </Typography>
        </div>
        <LinearProgress />
      </Container>
    </Wrapper>
  );
};

export default LazyLoadingFallback;

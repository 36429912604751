import { useMemo } from 'react';

export const useAxtPagination = (
  page: number,
  size: number,
  totalItems: number
) => {
  const value = useMemo(() => {
    const firstItem = page * size + 1;
    const lastItem =
      (page + 1) * size < totalItems ? (page + 1) * size : totalItems;
    const totalPages = totalItems / size;
    const totalPagesLimited = totalPages > 1 ? Math.ceil(totalPages) : 1;
    const currentPage = page + 1;

    return {
      firstItem,
      lastItem,
      totalItems,
      totalPages: totalPagesLimited,
      currentPage,
    };
  }, [page, size, totalItems]);

  return value;
};

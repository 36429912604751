import React, { useCallback, useImperativeHandle, useState } from 'react';
import { Box, Button, Popover, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const useStyles = makeStyles((theme: Theme) => ({
  menu: {
    color: theme.palette.grey[600],
  },
  box: {
    minWidth: '300px',
    padding: theme.spacing(3),
  },
}));

type AxtSearchMenuProps = {
  filterMenuLabel: JSX.Element | string;
  menuRef: any;
  children: React.ReactNode;
};

const AxtSearchMenu = ({
  filterMenuLabel,
  menuRef,
  children,
}: AxtSearchMenuProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(e.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  useImperativeHandle(menuRef, () => ({
    close: () => {
      handleClose();
    },
  }));

  return (
    <div>
      <Button
        variant="text"
        size="medium"
        aria-label="filter-button"
        className={classes.menu}
        endIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
      >
        {filterMenuLabel}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className={classes.box}
        >
          {children}
        </Box>
      </Popover>
    </div>
  );
};

export default AxtSearchMenu;

import React from 'react';

const Error404 = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 3rem',
        height: '100vh',
      }}
    >
      <svg
        width="612"
        height="250"
        viewBox="0 0 612 250"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0)">
          <path
            d="M307.883 243.878C473.789 243.878 608.282 234.974 608.282 223.991C608.282 213.007 473.789 204.104 307.883 204.104C141.978 204.104 7.48462 213.007 7.48462 223.991C7.48462 234.974 141.978 243.878 307.883 243.878Z"
            fill="#F2F2F2"
          />
          <path
            d="M296.37 6.28009C247.426 6.28009 212.286 37.6807 212.286 110.949C212.286 193.986 247.426 215.966 296.37 215.966C345.313 215.966 382.547 191.892 382.547 110.949C382.547 24.4226 345.313 6.28009 296.37 6.28009ZM296.691 188.403C262.792 188.403 245.082 168.516 245.082 111.002C245.082 60.2552 263.517 33.4939 297.416 33.4939C331.315 33.4939 349.751 51.0724 349.751 111.002C349.75 167.066 330.59 188.403 296.691 188.403Z"
            fill="#2F2E41"
          />
          <path
            d="M182.979 158.049H162.743V98.4598C162.743 95.2948 161.485 92.2593 159.247 90.0213C157.009 87.7833 153.974 86.526 150.809 86.526H146.067C144.5 86.526 142.948 86.8346 141.5 87.4344C140.052 88.0341 138.737 88.9131 137.629 90.0213C136.52 91.1295 135.641 92.4451 135.042 93.8929C134.442 95.3408 134.133 96.8926 134.133 98.4598V158.049H77.0546C75.8804 158.049 74.7261 157.746 73.7037 157.168C72.6814 156.591 71.8256 155.759 71.2195 154.753C70.6134 153.747 70.2776 152.602 70.2445 151.428C70.2115 150.255 70.4824 149.092 71.0309 148.054L131.442 33.7144C132.195 32.2889 132.652 30.7255 132.784 29.1187C132.917 27.5119 132.722 25.8949 132.212 24.3654C131.702 22.8359 130.888 21.4255 129.818 20.2195C128.748 19.0135 127.444 18.0367 125.987 17.3483L122.42 15.6642C119.651 14.3566 116.485 14.1635 113.578 15.1249C110.67 16.0864 108.244 18.1287 106.8 20.8294L33.1203 158.661C31.8656 161.008 31.2092 163.629 31.2092 166.29V166.29C31.2092 168.415 31.6278 170.519 32.441 172.483C33.2542 174.446 34.4462 176.23 35.9489 177.732C37.4515 179.235 39.2354 180.427 41.1987 181.24C43.162 182.053 45.2663 182.472 47.3914 182.472H134.133V220.153C134.133 222.031 134.503 223.891 135.222 225.627C135.941 227.362 136.995 228.939 138.323 230.268C139.651 231.596 141.228 232.65 142.964 233.369C144.699 234.087 146.559 234.457 148.438 234.457H148.438C150.316 234.457 152.177 234.087 153.912 233.369C155.648 232.65 157.225 231.596 158.553 230.268C159.881 228.939 160.935 227.362 161.654 225.627C162.373 223.891 162.743 222.031 162.743 220.153V182.472H182.979C186.217 182.472 189.323 181.186 191.613 178.895C193.903 176.605 195.19 173.499 195.19 170.261V170.261C195.19 167.022 193.903 163.916 191.613 161.626C189.323 159.336 186.217 158.049 182.979 158.049V158.049Z"
            fill="#0000DE"
          />
          <path
            d="M557.692 158.049H537.456V98.4598C537.456 95.2948 536.199 92.2593 533.961 90.0213C531.723 87.7833 528.687 86.526 525.522 86.526H520.781C519.213 86.526 517.662 86.8346 516.214 87.4344C514.766 88.0341 513.45 88.9131 512.342 90.0213C511.234 91.1295 510.355 92.4451 509.755 93.8929C509.155 95.3408 508.847 96.8926 508.847 98.4598V158.049H451.768C450.594 158.049 449.439 157.746 448.417 157.168C447.395 156.591 446.539 155.759 445.933 154.753C445.327 153.747 444.991 152.602 444.958 151.428C444.925 150.255 445.196 149.092 445.744 148.054L506.156 33.7144C506.909 32.2889 507.365 30.7255 507.498 29.1187C507.63 27.5119 507.435 25.8949 506.925 24.3654C506.416 22.8359 505.601 21.4255 504.531 20.2195C503.461 19.0135 502.158 18.0367 500.7 17.3483L497.134 15.6642C494.365 14.3566 491.199 14.1635 488.291 15.1249C485.384 16.0864 482.957 18.1287 481.513 20.8294L407.834 158.661C406.579 161.008 405.923 163.629 405.923 166.29V166.29C405.923 170.582 407.628 174.698 410.662 177.732C413.697 180.767 417.813 182.472 422.105 182.472H508.847V220.153C508.847 223.947 510.354 227.585 513.036 230.268C515.719 232.95 519.357 234.457 523.151 234.457V234.457C526.945 234.457 530.584 232.95 533.266 230.268C535.949 227.585 537.456 223.947 537.456 220.153V182.472H557.692C560.931 182.472 564.037 181.186 566.327 178.895C568.617 176.605 569.903 173.499 569.903 170.261V170.261C569.903 167.022 568.617 163.916 566.327 161.626C564.037 159.336 560.931 158.049 557.692 158.049Z"
            fill="#0000DE"
          />
          <path
            d="M188.561 151.072H168.325V91.4819C168.325 89.9147 168.017 88.3629 167.417 86.915C166.817 85.4671 165.938 84.1516 164.83 83.0434C163.722 81.9353 162.406 81.0562 160.958 80.4565C159.51 79.8567 157.959 79.5481 156.391 79.5481H151.65C148.485 79.5481 145.449 80.8054 143.211 83.0434C140.973 85.2814 139.716 88.3169 139.716 91.4819V151.072H82.6371C81.4629 151.072 80.3086 150.768 79.2863 150.191C78.2639 149.613 77.4081 148.781 76.802 147.775C76.1959 146.77 75.8601 145.624 75.827 144.45C75.794 143.277 76.0649 142.114 76.6134 141.076L137.025 26.7365C137.778 25.311 138.235 23.7476 138.367 22.1408C138.499 20.534 138.304 18.917 137.795 17.3875C137.285 15.858 136.47 14.4476 135.4 13.2416C134.33 12.0356 133.027 11.0588 131.569 10.3704L128.003 8.68633C125.234 7.37869 122.068 7.18561 119.16 8.14703C116.253 9.10845 113.826 11.1508 112.382 13.8515L38.7028 151.683C37.4482 154.03 36.7917 156.651 36.7917 159.312V159.312C36.7917 163.604 38.4966 167.72 41.5314 170.755C44.5661 173.789 48.6821 175.494 52.9739 175.494H139.716V213.175C139.716 215.053 140.086 216.913 140.805 218.649C141.524 220.385 142.577 221.961 143.906 223.29C145.234 224.618 146.811 225.672 148.546 226.391C150.282 227.11 152.142 227.48 154.02 227.48H154.02C157.814 227.48 161.453 225.972 164.135 223.29C166.818 220.607 168.325 216.969 168.325 213.175V175.494H188.561C190.165 175.494 191.753 175.178 193.234 174.565C194.716 173.951 196.062 173.052 197.196 171.918C198.33 170.784 199.229 169.438 199.843 167.956C200.457 166.474 200.772 164.886 200.772 163.283V163.283C200.772 161.679 200.457 160.091 199.843 158.61C199.229 157.128 198.33 155.782 197.196 154.648C196.062 153.514 194.716 152.615 193.234 152.001C191.753 151.387 190.165 151.072 188.561 151.072V151.072Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M568.159 151.072H547.923V91.4819C547.923 88.3169 546.666 85.2814 544.427 83.0434C542.189 80.8054 539.154 79.5481 535.989 79.5481H531.247C529.68 79.5481 528.128 79.8567 526.68 80.4565C525.233 81.0562 523.917 81.9353 522.809 83.0434C521.701 84.1516 520.822 85.4671 520.222 86.915C519.622 88.3629 519.313 89.9147 519.313 91.4819V151.072H462.235C461.061 151.072 459.906 150.768 458.884 150.191C457.862 149.613 457.006 148.781 456.4 147.775C455.794 146.77 455.458 145.624 455.425 144.45C455.392 143.277 455.663 142.114 456.211 141.076L516.622 26.7365C517.376 25.311 517.832 23.7476 517.964 22.1408C518.097 20.534 517.902 18.917 517.392 17.3875C516.882 15.858 516.068 14.4476 514.998 13.2416C513.928 12.0356 512.625 11.0588 511.167 10.3704L507.6 8.68633C504.831 7.37869 501.665 7.18561 498.758 8.14703C495.85 9.10845 493.424 11.1508 491.98 13.8515L418.3 151.683C417.046 154.03 416.389 156.651 416.389 159.312C416.389 163.604 418.094 167.72 421.129 170.755C424.164 173.789 428.28 175.494 432.572 175.494H519.313V213.175C519.313 216.969 520.821 220.607 523.503 223.29C526.186 225.972 529.824 227.48 533.618 227.48V227.48C537.412 227.48 541.05 225.972 543.733 223.29C546.416 220.607 547.923 216.969 547.923 213.175V175.494H568.159C569.762 175.494 571.35 175.178 572.832 174.565C574.313 173.951 575.659 173.052 576.793 171.918C577.927 170.784 578.827 169.438 579.44 167.956C580.054 166.474 580.37 164.886 580.37 163.283V163.283C580.37 160.044 579.084 156.938 576.793 154.648C574.503 152.358 571.397 151.072 568.159 151.072V151.072Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M307.534 0C258.591 0 223.451 31.4006 223.451 104.669C223.451 187.706 258.591 209.686 307.534 209.686C356.477 209.686 393.711 185.612 393.711 104.669C393.711 18.1425 356.477 0 307.534 0ZM307.856 182.123C273.957 182.123 256.247 162.235 256.247 104.722C256.247 53.9751 274.682 27.2138 308.581 27.2138C342.48 27.2138 360.915 44.7923 360.915 104.722C360.915 160.786 341.755 182.123 307.856 182.123V182.123Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M328.156 16.0631C327.713 17.8817 325.805 19.3382 323.692 20.3558C319.249 22.4952 313.768 23.1336 308.453 23.3479C307.097 23.4303 305.736 23.4052 304.384 23.2727C301.764 23.0193 299.306 21.8894 297.408 20.0658C297.272 19.9774 297.161 19.8562 297.085 19.7134C297.009 19.5706 296.971 19.4109 296.973 19.2491C296.976 19.0874 297.019 18.9289 297.099 18.7885C297.18 18.6481 297.294 18.5304 297.433 18.4462C300.263 15.3375 304.985 13.4314 309.742 12.0589C313.35 11.0183 319.641 8.32269 323.575 8.68646C327.386 9.03884 328.714 13.7669 328.156 16.0631Z"
            fill="#3F3D56"
          />
          <path
            opacity="0.1"
            d="M328.156 16.0631C327.713 17.8817 325.805 19.3382 323.692 20.3558C319.249 22.4952 313.768 23.1336 308.453 23.3479C307.097 23.4303 305.736 23.4052 304.384 23.2727C301.764 23.0193 299.306 21.8894 297.408 20.0658C297.272 19.9774 297.161 19.8562 297.085 19.7134C297.009 19.5706 296.971 19.4109 296.973 19.2491C296.976 19.0874 297.019 18.9289 297.099 18.7885C297.18 18.6481 297.294 18.5304 297.433 18.4462C300.263 15.3375 304.985 13.4314 309.742 12.0589C313.35 11.0183 319.641 8.32269 323.575 8.68646C327.386 9.03884 328.714 13.7669 328.156 16.0631Z"
            fill="black"
          />
          <path
            d="M573.384 150.096C575.11 150.723 576.957 150.941 578.78 150.732C580.604 150.524 582.354 149.894 583.893 148.894C585.432 147.893 586.718 146.549 587.649 144.967C588.58 143.385 589.131 141.609 589.258 139.778C589.385 137.946 589.085 136.111 588.382 134.415C587.678 132.72 586.591 131.211 585.205 130.007C583.819 128.804 582.172 127.939 580.395 127.48C578.618 127.022 576.758 126.982 574.963 127.365L575.259 133.465L572.373 128.237C569.541 129.568 567.352 131.969 566.289 134.913C565.912 135.944 565.682 137.023 565.607 138.119C565.425 140.689 566.086 143.249 567.489 145.41C568.893 147.571 570.962 149.216 573.384 150.096V150.096Z"
            fill="#57B894"
          />
          <path
            d="M587.665 220.849C586.647 215.661 591.061 211.062 595.422 208.072C599.783 205.083 604.878 202.145 606.366 197.072C608.505 189.78 602.135 183.103 597.177 177.344C593.499 173.071 590.341 168.376 587.768 163.359C586.736 161.348 585.788 159.245 585.517 157C585.126 153.768 586.164 150.552 587.21 147.469C590.695 137.199 594.421 127.015 598.388 116.917"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M573.873 148.525C575.598 149.152 577.445 149.37 579.269 149.161C581.092 148.953 582.843 148.324 584.382 147.323C585.921 146.323 587.206 144.979 588.137 143.397C589.068 141.815 589.619 140.038 589.746 138.207C589.873 136.376 589.573 134.54 588.87 132.845C588.166 131.149 587.079 129.64 585.693 128.437C584.307 127.233 582.66 126.368 580.883 125.91C579.106 125.451 577.246 125.411 575.451 125.794L575.748 131.894L572.861 126.666C570.029 127.997 567.84 130.399 566.777 133.342C566.4 134.373 566.17 135.453 566.096 136.548C565.913 139.119 566.574 141.678 567.977 143.84C569.381 146.001 571.45 147.646 573.873 148.525V148.525Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M586.217 114.81C586.894 112.942 588.03 111.274 589.521 109.961C591.012 108.648 592.809 107.73 594.748 107.294L595.723 113.094L597.533 107.003C600.045 107.04 602.48 107.875 604.487 109.386C606.494 110.898 607.968 113.008 608.697 115.412C609.427 117.816 609.373 120.39 608.545 122.761C607.716 125.133 606.155 127.18 604.087 128.607C602.019 130.033 599.551 130.766 597.04 130.699C594.528 130.632 592.103 129.768 590.115 128.233C588.126 126.697 586.677 124.57 585.976 122.157C585.276 119.745 585.36 117.172 586.217 114.81H586.217Z"
            fill="#57B894"
          />
          <path
            d="M586.868 112.716C587.545 110.848 588.681 109.18 590.172 107.867C591.663 106.553 593.46 105.636 595.398 105.2L596.374 111L598.184 104.909C600.696 104.946 603.131 105.78 605.137 107.292C607.144 108.803 608.619 110.914 609.348 113.318C610.078 115.722 610.024 118.295 609.195 120.667C608.367 123.039 606.806 125.086 604.738 126.513C602.67 127.939 600.202 128.672 597.691 128.605C595.179 128.537 592.754 127.674 590.766 126.139C588.777 124.603 587.328 122.476 586.627 120.063C585.927 117.65 586.011 115.078 586.868 112.716H586.868Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M595.097 177.462C597.833 178.454 600.838 178.405 603.54 177.324C606.242 176.243 608.452 174.205 609.748 171.6C611.045 168.994 611.337 166.003 610.569 163.195C609.802 160.388 608.028 157.962 605.586 156.378L604.196 161.059L603.21 155.191C603.2 155.186 603.19 155.183 603.18 155.179C601.717 154.648 600.163 154.409 598.607 154.478C597.052 154.547 595.525 154.922 594.115 155.581C592.704 156.24 591.437 157.171 590.387 158.319C589.336 159.468 588.522 160.813 587.991 162.277C587.46 163.74 587.222 165.294 587.292 166.85C587.362 168.405 587.738 169.931 588.397 171.341C589.057 172.752 589.989 174.018 591.138 175.068C592.288 176.118 593.633 176.931 595.097 177.462H595.097Z"
            fill="#57B894"
          />
          <path
            d="M595.661 175.34C598.397 176.333 601.402 176.284 604.104 175.203C606.806 174.122 609.016 172.084 610.312 169.479C611.608 166.873 611.901 163.881 611.133 161.074C610.365 158.267 608.592 155.84 606.15 154.257L604.76 158.938L603.774 153.07C603.764 153.065 603.754 153.061 603.744 153.058C602.281 152.527 600.727 152.288 599.171 152.357C597.616 152.426 596.089 152.801 594.679 153.46C593.268 154.119 592.001 155.049 590.951 156.198C589.9 157.347 589.086 158.692 588.555 160.155C588.024 161.619 587.786 163.173 587.856 164.728C587.926 166.284 588.302 167.81 588.961 169.22C589.621 170.63 590.553 171.897 591.702 172.947C592.852 173.997 594.197 174.81 595.661 175.34H595.661Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M582.758 197.144C583.228 199.821 584.605 202.254 586.657 204.037C588.708 205.819 591.31 206.841 594.026 206.933C596.742 207.024 599.407 206.179 601.574 204.539C603.741 202.899 605.278 200.563 605.928 197.924C606.577 195.285 606.299 192.503 605.141 190.045C603.983 187.587 602.014 185.601 599.566 184.422C597.118 183.242 594.338 182.94 591.694 183.567C589.049 184.194 586.701 185.71 585.042 187.863L590.322 195.811L583.107 191.593C582.548 193.388 582.428 195.292 582.758 197.144V197.144Z"
            fill="#57B894"
          />
          <path
            d="M583.246 195.574C583.717 198.25 585.093 200.684 587.145 202.466C589.196 204.248 591.798 205.271 594.515 205.362C597.231 205.454 599.896 204.609 602.063 202.968C604.23 201.328 605.767 198.993 606.416 196.354C607.065 193.715 606.788 190.933 605.629 188.474C604.471 186.016 602.503 184.031 600.054 182.851C597.606 181.672 594.826 181.37 592.182 181.996C589.537 182.623 587.189 184.14 585.53 186.293L590.811 194.24L583.595 190.022C583.036 191.818 582.916 193.722 583.246 195.574V195.574Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M5.12911 178.967C6.26254 179.38 7.47618 179.523 8.67437 179.386C9.87256 179.249 11.0226 178.835 12.0337 178.178C13.0449 177.521 13.8895 176.638 14.5012 175.598C15.1128 174.559 15.4746 173.391 15.5582 172.188C15.6417 170.985 15.4447 169.779 14.9826 168.665C14.5205 167.551 13.806 166.56 12.8954 165.769C11.9847 164.978 10.9029 164.41 9.73516 164.109C8.5674 163.807 7.34563 163.782 6.16613 164.033L6.36104 168.041L4.46476 164.606C2.60367 165.481 1.1659 167.058 0.467404 168.992C0.219339 169.67 0.068534 170.379 0.0194878 171.099C-0.100206 172.787 0.333853 174.469 1.25589 175.889C2.17792 177.309 3.53768 178.39 5.12911 178.967V178.967Z"
            fill="#57B894"
          />
          <path
            d="M14.5119 225.454C13.8425 222.045 16.7431 219.023 19.6082 217.059C22.4734 215.095 25.8211 213.165 26.7987 209.832C28.2036 205.041 24.0187 200.654 20.7614 196.87C18.3448 194.062 16.2696 190.978 14.579 187.682C13.8441 186.387 13.3435 184.973 13.1003 183.504C12.8434 181.38 13.5257 179.267 14.213 177.242C16.5026 170.494 18.9507 163.803 21.5571 157.168"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M5.44992 177.936C6.58335 178.348 7.79698 178.491 8.99517 178.354C10.1934 178.217 11.3434 177.803 12.3545 177.146C13.3657 176.489 14.2103 175.606 14.8219 174.566C15.4336 173.527 15.7954 172.36 15.8789 171.156C15.9625 169.953 15.7654 168.747 15.3034 167.633C14.8413 166.519 14.1268 165.528 13.2161 164.737C12.3055 163.947 11.2237 163.378 10.056 163.077C8.88821 162.776 7.66643 162.75 6.48694 163.001L6.68185 167.009L4.78557 163.574C2.92447 164.449 1.4867 166.026 0.788205 167.96C0.540143 168.638 0.38934 169.347 0.340291 170.067C0.220589 171.756 0.654646 173.437 1.57668 174.857C2.49872 176.277 3.85848 177.358 5.44992 177.936V177.936Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M13.5602 155.784C14.005 154.557 14.7515 153.461 15.731 152.598C16.7105 151.735 17.8915 151.133 19.165 150.846L19.8062 154.657L20.9949 150.655C22.6453 150.679 24.2453 151.227 25.5638 152.221C26.8823 153.214 27.8511 154.6 28.3303 156.18C28.8095 157.759 28.7743 159.45 28.2298 161.008C27.6854 162.567 26.6598 163.911 25.3011 164.849C23.9425 165.786 22.3211 166.267 20.6711 166.223C19.0211 166.179 17.4277 165.612 16.1212 164.603C14.8147 163.594 13.8625 162.197 13.4022 160.611C12.9418 159.026 12.9972 157.336 13.5602 155.784V155.784Z"
            fill="#57B894"
          />
          <path
            d="M13.9877 154.408C14.4325 153.181 15.179 152.085 16.1585 151.222C17.138 150.359 18.319 149.757 19.5925 149.47L20.2337 153.281L21.4224 149.279C23.0728 149.303 24.6728 149.852 25.9913 150.845C27.3098 151.838 28.2786 153.224 28.7578 154.804C29.237 156.383 29.2018 158.074 28.6573 159.632C28.1129 161.191 27.0873 162.536 25.7286 163.473C24.37 164.41 22.7486 164.892 21.0986 164.848C19.4485 164.803 17.8552 164.236 16.5487 163.227C15.2421 162.219 14.29 160.821 13.8297 159.236C13.3693 157.65 13.4247 155.96 13.9877 154.408V154.408Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M19.3945 196.947C21.192 197.599 23.1666 197.567 24.9419 196.857C26.7172 196.147 28.169 194.808 29.0207 193.096C29.8724 191.384 30.0644 189.419 29.5601 187.574C29.0558 185.73 27.8904 184.136 26.2861 183.095L25.3727 186.17L24.725 182.315C24.7186 182.312 24.712 182.31 24.7052 182.308C23.7437 181.958 22.7228 181.801 21.7007 181.847C20.6787 181.892 19.6755 182.138 18.7486 182.571C17.8217 183.004 16.9892 183.615 16.2987 184.37C15.6082 185.125 15.0732 186.008 14.7243 186.97C14.3754 187.932 14.2195 188.953 14.2654 189.975C14.3113 190.997 14.5582 192 14.9919 192.927C15.4256 193.853 16.0377 194.685 16.7931 195.375C17.5485 196.065 18.4325 196.599 19.3945 196.947V196.947Z"
            fill="#57B894"
          />
          <path
            d="M19.7651 195.554C21.5626 196.206 23.5371 196.174 25.3124 195.463C27.0877 194.753 28.5396 193.415 29.3913 191.703C30.243 189.991 30.435 188.025 29.9306 186.181C29.4263 184.336 28.2609 182.742 26.6566 181.702L25.7433 184.777L25.0956 180.922C25.0892 180.919 25.0825 180.916 25.0758 180.914C24.1145 180.565 23.094 180.409 22.0724 180.455C21.0509 180.501 20.0484 180.747 19.1221 181.18C17.2514 182.055 15.8048 183.637 15.1005 185.579C14.3963 187.52 14.4921 189.662 15.3669 191.532C16.2416 193.403 17.8237 194.85 19.7651 195.554V195.554Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
          <path
            d="M11.2876 209.879C11.5967 211.638 12.501 213.237 13.849 214.408C15.197 215.579 16.9066 216.251 18.6911 216.311C20.4757 216.371 22.2266 215.815 23.6503 214.738C25.074 213.66 26.0839 212.126 26.5105 210.392C26.9372 208.658 26.7547 206.83 25.9937 205.215C25.2327 203.6 23.9394 202.295 22.3308 201.52C20.7222 200.745 18.896 200.547 17.1585 200.959C15.4211 201.371 13.878 202.367 12.7882 203.782L16.2576 209.003L11.5171 206.232C11.1495 207.412 11.0708 208.663 11.2876 209.879V209.879Z"
            fill="#57B894"
          />
          <path
            d="M11.6084 208.847C11.9175 210.606 12.8218 212.205 14.1698 213.376C15.5178 214.547 17.2274 215.219 19.0119 215.279C20.7965 215.339 22.5474 214.783 23.9711 213.706C25.3948 212.628 26.4047 211.094 26.8313 209.36C27.258 207.626 27.0755 205.798 26.3145 204.183C25.5535 202.568 24.2602 201.263 22.6516 200.488C21.043 199.713 19.2168 199.515 17.4793 199.927C15.7419 200.339 14.1988 201.335 13.109 202.75L16.5784 207.972L11.8378 205.2C11.4703 206.38 11.3916 207.631 11.6084 208.847V208.847Z"
            stroke="#3F3D56"
            strokeMiterlimit="10"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="611.617" height="250" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Error404;

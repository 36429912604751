declare const location: Location;

/**
 * Returns identity provider hint from current location
 * ex: localhost:3000/<idp>/something/else
 */
export function getIdpHint() {
  const path = location.pathname.split('/');
  if (path.length > 1) {
    return path[1];
  }
  return '';
}

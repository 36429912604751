import React, { ReactNode } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { VariantContext } from '../../context/variant';
import muiTheme from '../../mui-theme';

type Props = {
  children: ReactNode;
};

function MuiThemeProvider({ children }: Props) {
  return (
    <VariantContext.Provider value={'IDN'}>
      <ThemeProvider theme={muiTheme}>
        <div>{children}</div>
      </ThemeProvider>
    </VariantContext.Provider>
  );
}

export default MuiThemeProvider;

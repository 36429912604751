import React, { useCallback, useEffect, useState } from 'react';
import { styled, Tabs, Tab } from '@mui/material';
import { useHistory, useLocation } from 'react-router-dom';
import { colors, typography } from 'portal-design-system';
import { usePortalContext } from 'context';
import { AppConfig } from 'types';
import useDefaultAppPath from 'hooks/useDefaultAppPath';
import { DEFAULT_TAB } from 'config/portal.conf';
import IDNLogo from './Logo/IDnowLogo';
import AccountSection from './AccountSection';

function getTab(
  currentPath: string,
  defaultAppPath: string,
  apps: AppConfig[]
) {
  // Search for tab index by matching current route with app route
  for (let i = 0; i < apps.length; i++) {
    const app = apps[i];
    if (currentPath.match(new RegExp(app.appPath))) {
      return i;
    }
  }
  // Current route not found: we get redirected to default app
  for (let i = 0; i < apps.length; i++) {
    const app = apps[i];
    if (app.appPath === defaultAppPath) {
      return i;
    }
  }
  return 0;
}

const Header = styled('header')(({ theme }) => ({
  height: '64px',
  borderBottom: `1px solid ${colors.black12}`,
  padding: `0 ${theme.spacing(2)}`,
  boxSizing: 'border-box',
  zIndex: 1,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const LogoWrapper = styled('a')({
  cursor: 'pointer',
});

const LeftSection = styled('div')({
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    marginRight: 50,
  },
  '& .MuiTabs-scroller': {
    display: 'flex',
    alignItems: 'center',
  },
});

function PortalHeader() {
  const history = useHistory();
  const { pathname } = useLocation();
  const defaultAppPath = useDefaultAppPath();

  const [{ portalApps }] = usePortalContext();
  const [currentTab, setCurrentTab] = useState(
    getTab(pathname, defaultAppPath, portalApps)
  );

  useEffect(() => {
    setCurrentTab(getTab(pathname, defaultAppPath, portalApps));
  }, [defaultAppPath, pathname, portalApps]);

  const handleChange = useCallback(
    (event, newValue) => {
      history.push(portalApps[newValue].appPath);
    },
    [history, portalApps]
  );

  const goToDefaultTab = useCallback(() => {
    handleChange(null, DEFAULT_TAB);
  }, [handleChange]);

  return (
    <Header>
      <LeftSection>
        <LogoWrapper onClick={goToDefaultTab} id="logoHome">
          <IDNLogo />
        </LogoWrapper>
        <Tabs
          value={currentTab}
          onChange={handleChange}
          aria-label="navigation tabs"
          sx={{ minHeight: 64 }}
          // Disable transition because it's laggy
          TabIndicatorProps={{
            style: { transition: 'none' },
          }}
        >
          {portalApps.map((appConfig) => (
            <Tab
              key={appConfig.appName}
              id={appConfig.testId}
              component="a"
              onClick={(event: any) => {
                // don't refresh the page
                event.preventDefault();
              }}
              label={appConfig.appName}
              sx={{
                fontSize: typography.fontSize.lg,
                fontWeight: typography.fontWeight.medium,
                textTransform: 'capitalize',
                marginRight: '50px',
              }}
            />
          ))}
        </Tabs>
      </LeftSection>
      <AccountSection />
    </Header>
  );
}

export default PortalHeader;

export const SPACING_SCALING_FACTOR = 8;

export function spacing(...args: any) {
  if (process.env.NODE_ENV !== 'production') {
    if (args.length === 0) {
      console.error(
        `spacing: Not enough arguments provided, expected between 1 and 4, got ${args.length}`
      );
      return '';
    }
    if (args.length > 4) {
      console.error(
        `spacing: Too many arguments provided, expected between 0 and 4, got ${args.length}`
      );
      return '';
    }
  }
  return args
    .map((argument: any) => {
      return typeof argument === 'number'
        ? `${argument * SPACING_SCALING_FACTOR}px`
        : argument;
    })
    .join(' ');
}

import React from 'react';
import { Button, Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& > div': {
      margin: theme.spacing(3, 0, 1, 2),
    },
  },
}));

type AxtSearchMenuFooterProps = {
  clearLabel: JSX.Element | string;
  submitLabel: JSX.Element | string;
  onClear: () => void;
  onSubmit: () => void;
};

function AxtSearchMenuFooter({
  clearLabel,
  submitLabel,
  onClear,
  onSubmit,
}: AxtSearchMenuFooterProps) {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      className={classes.root}
    >
      <Grid item>
        <Button variant="text" aria-label="clear-button" onClick={onClear}>
          {clearLabel}
        </Button>
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          color="primary"
          aria-label="apply-button"
          onClick={onSubmit}
        >
          {submitLabel}
        </Button>
      </Grid>
    </Grid>
  );
}

export default AxtSearchMenuFooter;

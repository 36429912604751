import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { IconButton, Theme } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AxtTooltip from '../../../AxtTooltip';
import AxtTooltipContent from '../../../AxtTooltipContent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      padding: theme.spacing(1),
    },
  })
);

type InputClearProps = {
  tooltipTextContent: React.ReactNode;
  onClear: () => void;
  size?: 'small' | 'medium';
};

export default function InputClear({
  tooltipTextContent,
  onClear,
  size,
}: InputClearProps) {
  const classes = useStyles();

  return (
    <AxtTooltip
      aria-label="clear-icon-button-tooltip"
      title={<AxtTooltipContent textContent={tooltipTextContent} />}
    >
      <IconButton
        aria-label="clear-button"
        className={classes.iconButton}
        onClick={onClear}
        type="button"
        size="small"
      >
        <ClearIcon fontSize={size === 'medium' ? 'inherit' : 'small'} />
      </IconButton>
    </AxtTooltip>
  );
}

import { wrapReducer } from '@fthebaud/reducer-logger';
import { PortalState } from 'types';
import { defaultLanguage, languages } from 'utils/langs';
import localStorage from 'utils/localStorage';
import { getAuthorizedApps } from '../utils/portal';
import { Action } from './portal.actions';

// get previous selected lang
function getDefaultLanguage() {
  const langs = [localStorage.getLanguage(), navigator.language];
  const selectedLang = langs.find((lang) => languages.some((l) => l === lang));
  return selectedLang || defaultLanguage;
}

export const initialState: PortalState = {
  portalApps: [],
  language: getDefaultLanguage(),
};

const reducer = (state: PortalState, action: Action): PortalState => {
  switch (action.type) {
    case 'LOGIN': {
      const { user, identityProvider } = action.payload;
      const portalApps = getAuthorizedApps(identityProvider);
      return {
        ...state,
        user,
        portalApps,
      };
    }
    case 'SET_LANGUAGE':
      if (action.payload.shouldPersist) {
        // save the language in the localStorage for future usage
        localStorage.setLanguage(action.payload.language);
      }
      return { ...state, language: action.payload.language };
    default:
      return state;
  }
};

export const portalReducer = wrapReducer<PortalState, Action>(reducer, {
  disabled:
    process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'test',
  displayName: 'portal',
});

import { User } from 'types';

export type Dispatch = (action: Action) => void;

export type Action =
  | {
      type: 'LOGIN';
      payload: {
        user: User;
        identityProvider: string;
      };
    }
  | {
      type: 'SET_LANGUAGE';
      payload: { language: string; shouldPersist: boolean };
    };

export function setLanguage(language: string, shouldPersist: boolean): Action {
  return {
    type: 'SET_LANGUAGE',
    payload: { language, shouldPersist },
  };
}

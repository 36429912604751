import React from 'react';
import clsx from 'clsx';
import { Link, Theme } from '@mui/material';
// /!\ @mui/styles IS DEPRECATED
import { makeStyles, createStyles } from '@mui/styles';

const useStyle = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'block',
        height: '1.2rem',
        marginTop: '0.3rem',
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: '1.2rem',
        marginLeft: '0.4rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        textDecoration: 'none',
      },
      link: {
        '&:hover': {
          color: theme.palette.primary.main,
          cursor: 'pointer',
        },
        '&:disabled': {
          color: theme.palette.grey[400],
          cursor: 'auto',
          textDecoration: 'none',
        },
      },
      disabled: {
        color: theme.palette.grey[400],
      },
      active: {
        color: theme.palette.common.black,
      },
      completed: {
        color: theme.palette.grey[800],
      },
    }),
  { name: 'AxtStepLabelStyle' }
);

export interface StepLabelProps extends React.HTMLAttributes<HTMLLinkElement> {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent) => void;
  active?: boolean;
  completed?: boolean;
  disabled?: boolean;
  error?: boolean;
}

const StepLabel: React.FC<StepLabelProps> = ({
  children,
  onClick,
  active,
  completed,
  disabled,
}) => {
  const classes = useStyle();
  const Component = onClick
    ? {
        Tag: Link,
        props: {
          component: 'button',
          disabled,
          onClick,
        },
        className: { [classes.completed]: completed, [classes.link]: onClick },
      }
    : {
        Tag: 'div',
        className: { [classes.disabled]: disabled },
      };

  return (
    <Component.Tag
      className={clsx(classes.root, {
        ...{ [classes.active]: active },
        ...Component.className,
      })}
      {...Component.props}
    >
      {children}
    </Component.Tag>
  );
};

export default StepLabel;

import React, { useCallback } from 'react';
import { Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  tags: {
    textAlign: 'left',
    '& > div': {
      marginRight: '4px',
    },
  },
}));

type Filter = { [attr: string]: string | string[] | undefined };
type AxtSearchChipsProps = {
  // allow to remove an attribute for text searchBar
  hide: string;
  // filter values from AxtSearchBar
  filter: Filter;
  // callback send all filter values after chips removed
  onChange: (filter: Filter) => any;
};

const AxtSearchChips = ({ hide, filter, onChange }: AxtSearchChipsProps) => {
  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [hide]: _, ...menuFiltering } = filter;
  const categories = Object.keys(menuFiltering).map((key) => {
    const values = menuFiltering[key] || [];
    return {
      key,
      values: Array.isArray(values) ? values : [values],
    };
  });

  const handleDelete = useCallback(
    (key, values, value) => {
      onChange({
        ...filter,
        [key]: values?.filter((el: any) => el !== value),
      });
    },
    [filter, onChange]
  );

  return (
    <div className={classes.tags}>
      {categories.map(({ key, values }) =>
        values.map((value) => (
          <Chip
            label={value}
            key={`chips-${value}`}
            color="secondary"
            size="small"
            onDelete={() => handleDelete(key, values, value)}
          />
        ))
      )}
    </div>
  );
};

export default AxtSearchChips;

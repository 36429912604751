import { Tooltip, Theme } from '@mui/material';
import { withStyles } from '@mui/styles';

const AxtTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.primary,
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  arrow: {
    color: theme.palette.grey[300],
    fontSize: 10,
  },
}))(Tooltip) as typeof Tooltip;

export default AxtTooltip;

import React, { useState, MouseEvent, useCallback } from 'react';
import { DayPicker, DateRange } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { Popover, styled } from '@mui/material';
// eslint-disable-next-line import/no-duplicates
import { subMonths } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { enUS, fr } from 'date-fns/locale';
import { colors } from '../../design-tokens';
import { getToday } from '../../utils/dateRange';
import CustomDateRanges from './CustomDateRanges';
import BottomBar from './BottomBar';
import DateRangeField from './DateRangeField';

const Container = styled('div')({
  gridTemplateColumns: 'auto auto',
  // React Day Picker CSS class override ("styles" props doesn't work for selected days)
  '& .rdp': {
    minHeight: 320,
  },
  '& .rdp-day_selected:not([aria-disabled="true"])': {
    backgroundColor: colors.turquoise,
    border: 0,
    borderRight: '1px solid hsla(0deg 0% 100% / 30%)',
  },
  '& .rdp-day_selected:focus:not([aria-disabled="true"]), .rdp-button:focus': {
    border: 'none',
  },
  '& .rdp-button:hover:not([aria-disabled="true"])': {
    backgroundColor: colors.lightTurquoise,
  },
});

type Props = {
  range?: DateRange;
  setRange: (dateRange: DateRange | undefined) => void;
  showCustomRanges?: boolean;
  showPreviousMonth?: boolean;
  language?: string;
};

function DateRangePicker({
  range,
  setRange,
  showCustomRanges,
  showPreviousMonth,
  language,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  // we want to show the current month and the previous month (instead of the next one)
  const today = getToday();
  const currentMonth = showPreviousMonth
    ? subMonths(range?.to || today, 1)
    : today;
  const [month, setMonth] = useState<Date>(currentMonth);

  const [calendarRange, setCalendarRange] = useState<DateRange | undefined>(
    range
  );
  const handleClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleCancel = useCallback(() => {
    // close popover
    setAnchorEl(null);
    // reset value
    setCalendarRange(range);
    if (range?.from) {
      setMonth(range.from);
    }
  }, [range]);

  const handleApply = useCallback(() => {
    // close popover
    setAnchorEl(null);
    setRange(calendarRange);
  }, [calendarRange, setRange]);

  const selectCustomRange = useCallback((range: DateRange) => {
    setCalendarRange(range);
    if (range?.from) {
      // position calendar to previous month
      setMonth(range.from);
    }
  }, []);

  return (
    <>
      <DateRangeField range={range} handleClick={handleClick} />
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleCancel}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        data-testid="DateRangePickerPopover"
      >
        <Container
          style={{
            display: showCustomRanges ? 'grid' : 'block',
          }}
        >
          {showCustomRanges && (
            <CustomDateRanges selectCustomRange={selectCustomRange} />
          )}
          <DayPicker
            month={month}
            onMonthChange={setMonth}
            numberOfMonths={2}
            mode="range"
            selected={calendarRange}
            onSelect={setCalendarRange}
            locale={language === 'fr' ? fr : enUS}
          />
          <BottomBar
            handleApply={handleApply}
            handleCancel={handleCancel}
            calendarRange={calendarRange}
          />
        </Container>
      </Popover>
    </>
  );
}

export default DateRangePicker;

export { DateRange };

import React from 'react';
import clsx from 'clsx';
import { Link, Typography, Theme } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      padding: theme.spacing(0, 1),
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    icon: {
      fontSize: '0.8rem',
    },
  })
);

function AxtBackButton({ component, className, children, ...props }: any) {
  const classes = useStyles();
  return (
    <Link
      component={component}
      className={clsx(classes.root, className)}
      {...props}
    >
      <Typography variant="body1" component="span" color="primary">
        <ArrowBackIosIcon className={classes.icon} />
        {children}
      </Typography>
    </Link>
  );
}

export default AxtBackButton;

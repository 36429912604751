import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { styled } from '@mui/material';
import { MuiThemeProvider } from 'portal-design-system';
import { configureSentry } from 'utils/sentry';
import pjson from '../package.json';
import { PortalContextProvider } from './context';
import PortalErrorBoundary from './components/PortalErrorBoundary';
import I18nProvider from './components/I18nProvider';
import AuthGuardian from './components/AuthGuardian';
import Router from './components/Router';
import PortalHeader from './components/PortalHeader';
import PortalFooter from './components/PortalFooter';

configureSentry();

const PageContainer = styled('div')({
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
});

const AppContainer = styled('div')({
  flex: 1,
});

function App() {
  console.log('Version', pjson.version);
  return (
    <PortalErrorBoundary>
      <PortalContextProvider>
        <I18nProvider>
          <MuiThemeProvider>
            <AuthGuardian>
              <BrowserRouter>
                <PageContainer>
                  <PortalHeader />
                  <AppContainer>
                    <Router />
                  </AppContainer>
                  <PortalFooter />
                </PageContainer>
              </BrowserRouter>
            </AuthGuardian>
          </MuiThemeProvider>
        </I18nProvider>
      </PortalContextProvider>
    </PortalErrorBoundary>
  );
}

export default App;

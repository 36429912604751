import React, { SyntheticEvent, useCallback, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Menu, MenuItem, Button } from '@mui/material';
import { ArrowDropDown, TranslateOutlined } from '@mui/icons-material';
import { usePortalContext } from 'context';
import { setLanguage } from 'context/portal.actions';
import { languages } from 'utils/langs/langs';

function LanguageToogle() {
  const [{ language }, dispatch] = usePortalContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback(
    (e, lang: string) => {
      e.stopPropagation();
      dispatch(setLanguage(lang, true));
      setAnchorEl(null);
    },
    [setAnchorEl, dispatch]
  );

  const handleToggle = useCallback(
    (event: SyntheticEvent<any>) => {
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Button
      aria-owns={open ? 'toggle-menu' : undefined}
      aria-haspopup="true"
      onClick={handleToggle}
      style={{
        margin: '0 16px',
      }}
    >
      <TranslateOutlined sx={{ height: 16 }} />
      <Trans id={language} />
      <ArrowDropDown />
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        PaperProps={{
          style: {
            minWidth: '20ch',
          },
        }}
        onClose={handleClose}
        onClick={handleClose}
      >
        {languages.map((lang: string) => (
          <MenuItem
            key={`lang-${lang}`}
            style={{
              background: lang === language ? 'hsl(0deg 0% 0% / 8%)' : '',
            }}
            onClick={(e) => handleClick(e, lang)}
          >
            <Trans id={lang} />
          </MenuItem>
        ))}
      </Menu>
    </Button>
  );
}

export default LanguageToogle;

import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { ui, colors } from '../design-tokens';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

/*
 * FONTS : Uncomment the code below to use avenirNext font and update the theme
 * @todo :
 * - Host AvenirNext fonts in a CDN
 * - Update source url
 * - Define 3 variables (regular, demi, bold) to provide as an array of fonts to @font-face in MuiCssBaseline
 */
// const avNxtBoldUrl =
//   'http://127.0.0.1:18412/figma/font-file?file=%2Fhome%2Fdgros%2F.local%2Fshare%2Ffonts%2FAvenirNextLTPro-Bold.ttf';

// const avenirNext = {
//   fontFamily: 'AvenirNext LT Pro Bold',
//   fontStyle: 'normal',
//   fontWeight: 'normal',
//   src: `
//     local('AvenirNext LT Pro Bold'),
//     url(${avNxtBoldUrl}) format('truetype')
//   `,
// };

/*
 * TEST : OVERRIDING THEME WITH GOOGLE FONT CAVEAT
 * @todo : To delete later
 */
// const cvtRegUrl =
//   'https://fonts.gstatic.com/s/caveat/v7/Wnz6HAc5bAfYB2Q7ZjYd.ttf';

// const caveatFont: any = {
//   fontFamily: 'Caveat',
//   fontStyle: 'normal',
//   fontDisplay: 'swap',
//   fontWeight: '400',
//   src: `local('Caveat Regular'), local('Caveat-Regular'), url(${cvtRegUrl}) format('truetype')`,
// };

/*
 * Temporary use of Montserrat Google font
 * @todo : To delete later
 */
// const mntSrtRegUrl =
//   'https://fonts.gstatic.com/s/montserrat/v14/JTUSjIg1_i6t8kCHKm459Wlhzg.ttf';

// type FontType = {
//   fontFamily: string;
//   fontStyle: string;
//   fontWeight: string;
//   fontDisplay: 'swap' | 'auto' | 'block' | 'fallback' | 'optional' | undefined;
//   src: string;
// };

// const mntSrtRegularFont: FontType = {
//   fontFamily: 'Montserrat',
//   fontStyle: 'normal',
//   fontWeight: '400',
//   fontDisplay: 'swap',
//   src: `
//     local('Montserrat Regular),
//     local('Montserrat-Regular),
//     url(${mntSrtRegUrl}) format('ttf')
//   `,
// };

const theme = createTheme({
  palette: {
    primary: {
      light: colors.orange,
      main: colors.orange,
      dark: colors.orange,
      50: '#efe6fd',
      100: '#d4c1fa',
      200: '#b697f8',
      300: '#966af7',
      400: '#7b46f5',
      500: '#5b1bf1',
      600: '#4c16eb',
      700: '#330ae3',
      800: '#0000de',
      900: '#0000d9',
      contrastText: '#fff',
    },
    secondary: {
      main: '#000',
      100: '#f4f4f4',
      200: '#e7e7e7',
      700: '#404040',
      800: '#252525',
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableTouchRipple: true,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: ui.radius.button,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        textPrimary: {
          borderRadius: ui.radius.button,
        },
        containedPrimary: {
          borderRadius: ui.radius.button,
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: colors.orange,
            boxShadow: 'none',
          },
        },
        outlinedPrimary: {
          border: `2px solid ${colors.orange}`,
          borderRadius: ui.radius.button,
          '&:hover': {
            border: `2px solid ${colors.orange}`,
            backgroundColor: colors.white,
          },
        },
      },
    },
  },
  shape: {
    borderRadius: ui.radius.md,
  },
  status: {
    danger: orange[500],
  },
  typography: {
    fontFamily: 'Montserrat, sans-serif',
    h1: {
      fontSize: '2.4rem',
    },
    h2: {
      fontSize: '2.0rem',
    },
    h3: {
      fontSize: '1.8rem',
    },
    h4: {
      fontSize: '1.6rem',
    },
    h5: {
      fontSize: '1.4rem',
    },
  },
});

/*
 * THEME VARIANTS CUSTOMIZATION
 * https://material-ui.com/customization/typography/#variants
 */
// ...
//   typography: {
//     subtitle1: {
//       fontSize: 12,
//     },
//     body1: {
//       fontWeight: 500,
//     },
//     button: {
//       fontStyle: 'italic',
//     },
//   },
// ...

/*
 * SEMANTIC ELEMENT MAPPING CUSTOMIZATION
 * https://material-ui.com/components/typography/#changing-the-semantic-element
 */
// ...
// props: {
//   MuiTypography: {
//     variantMapping: {
//       h1: 'h2',
//       h2: 'h2',
//       h3: 'h2',
//       h4: 'h2',
//       h5: 'h2',
//       h6: 'h2',
//       subtitle1: 'h2',
//       subtitle2: 'h2',
//       body1: 'span',
//       body2: 'span',
//     },
//   },
// },
// ...

const axtTheme = responsiveFontSizes(theme);

export default axtTheme;

import * as React from 'react';

const IDnowLogo = () => (
  <svg
    viewBox="0 0 1024 225"
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M830.8 36.5C829 36.6 827.4 37.6 826.6 39.2L785.3 129.1L758.2 39.8C757.6 37.8 755.8 36.5 753.7 36.5H714.5C711.9 36.5 709.8 38.6 709.8 41.2C709.8 41.7 709.9 42.2 710 42.6L764.5 215.1C765.1 217 766.9 218.4 769 218.4H794.4C796.2 218.4 797.9 217.3 798.7 215.7L839.5 126.4L880.3 215.7C881.1 217.4 882.7 218.4 884.6 218.4H910C912 218.4 913.9 217.1 914.5 215.1L969 42.6C969.1 42.1 969.2 41.7 969.2 41.2C969.2 38.6 967.1 36.5 964.5 36.5H925.2C923.1 36.5 921.3 37.9 920.7 39.8L893.6 129.1L852.3 39.2C851.5 37.6 849.9 36.6 848.1 36.5H830.8V36.5Z"
      fill="#1C1F38"
    />
    <path
      d="M993.6 225C1010.39 225 1024 211.39 1024 194.6C1024 177.811 1010.39 164.2 993.6 164.2C976.811 164.2 963.2 177.811 963.2 194.6C963.2 211.39 976.811 225 993.6 225Z"
      fill="#FF6B40"
    />
    <path
      d="M401.7 31.5C373.9 31.5 353.7 44.9 344.1 52.4L341.2 40.2C340.7 38.1 338.8 36.6 336.6 36.6H310.3C307.7 36.6 305.6 38.7 305.6 41.3V213.8C305.6 216.4 307.7 218.5 310.3 218.5H349C351.6 218.5 353.7 216.4 353.7 213.8V101.3C356.3 96.4 372.3 79.1 396.9 79.1C417 79.1 428 92.6 428 117.1V213.7C428 216.3 430.1 218.4 432.7 218.4H471.2C473.8 218.4 475.9 216.3 475.9 213.7V118.8C476 61.4 451 31.5 401.7 31.5Z"
      fill="#1C1F38"
    />
    <path
      d="M603 31.5C549.6 31.5 506.3 74.9 506.3 128.3C506.3 181.7 549.6 225.1 603.1 225C656.5 225 699.9 181.7 699.8 128.2C699.7 74.8 656.4 31.5 603 31.5ZM652.4 128.3C652.4 155.6 630.2 177.7 602.9 177.7C575.6 177.7 553.5 155.5 553.5 128.2C553.5 100.9 575.7 78.8 603 78.8C630.2 78.9 652.3 101 652.4 128.3C652.4 128.3 652.4 128.2 652.4 128.3Z"
      fill="#1C1F38"
    />
    <path
      d="M42.5 0H4.60001C2.00001 0 0 2.1 0 4.7C0 4.7 0 213.7 0 213.8C0 216.4 2.10001 218.5 4.70001 218.5H42.5C45.1 218.5 47.2 216.4 47.2 213.8V4.7C47.2 2.1 45.1 0 42.5 0Z"
      fill="#1C1F38"
    />
    <path
      d="M166.5 0H91.4998C88.8998 0 86.7998 2.1 86.7998 4.7V213.8C86.7998 216.4 88.8998 218.5 91.4998 218.5H166.5C226 218.5 274.4 169.4 274.4 109C274.5 48.9 226.1 0 166.5 0ZM210.6 150.5C199.2 164.8 182.3 172.6 163 172.6H134.1V45.5H165.5C192.6 45.5 216.5 64.5 222.3 90.6C227.4 113 223.1 134.9 210.6 150.5Z"
      fill="#1C1F38"
    />
  </svg>
);

export default IDnowLogo;

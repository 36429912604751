import { createTheme } from '@mui/material/styles';
import { typography, ui, layout, colors } from '../design-tokens';

// Overriding the default theme.
// Default theme: https://mui.com/customization/default-theme/
const muiTheme = createTheme({
  /****************************/
  /******** TYPOGRAPHY*********/
  /****************************/
  typography: {
    fontFamily: typography.fontFamily.primary,
    h1: {
      fontSize: typography.fontSize.xxxxxl, // 2.375rem - 38px
    },
    h2: {
      fontSize: typography.fontSize.xxxxl, // 2rem - 32px
    },
    h3: {
      fontSize: typography.fontSize.xxxl, // 1.75rem - 28px
    },
    h4: {
      fontSize: typography.fontSize.xxl, // 1.5rem - 24px
    },
    h5: {
      fontSize: typography.fontSize.xl, // 1.25rem - 20px
    },
    h6: {
      fontSize: typography.fontSize.lg, // 1.125rem - 18px
    },
    subtitle1: {
      fontSize: typography.fontSize.base, // 1rem - 16px
    },
    subtitle2: {
      fontSize: typography.fontSize.sm, // 0.875rem - 14px
    },
    body1: {
      fontSize: typography.fontSize.base, // 1rem - 16px
    },
    body2: {
      fontSize: typography.fontSize.sm, // 0.875rem - 14px
    },
    caption: {
      fontSize: typography.fontSize.xs, // 0.75rem - 12px
    },
    overline: {
      fontSize: typography.fontSize.xxs, // 0.625rem - 10px
    },
    button: {
      fontSize: typography.fontSize.sm, // 0.875rem - 14px
    },
  },

  /****************************/
  /********** COLORS **********/
  /****************************/
  palette: {
    common: {
      white: colors.white,
      black: colors.black,
    },
    background: {
      default: colors.grey96,
    },
    primary: {
      main: colors.orange,
      light: colors.lightOrange,
    },
    secondary: {
      main: colors.extraDarkBlue,
      light: colors.darkBlue,
    },
    error: {
      main: colors.red,
    },
    warning: {
      main: colors.yellow,
    },
    info: {
      main: colors.blue,
    },
    success: {
      main: colors.turquoise,
    },
  },

  /****************************/
  /********** LAYOUT **********/
  /****************************/
  spacing: layout.spacingScalingFactor,

  /****************************/
  /************ UI ************/
  /****************************/
  shape: {
    borderRadius: ui.radius.xs,
  },

  zIndex: {
    mobileStepper: ui.layer[1],
    speedDial: ui.layer[2],
    appBar: ui.layer[3],
    drawer: ui.layer[4],
    modal: ui.layer[5],
    snackbar: ui.layer[6],
    tooltip: ui.layer[7],
  },

  shadows: [
    'none',
    ui.elevation[1],
    ui.elevation[2],
    ui.elevation[3],
    ui.elevation[4],
    ui.elevation[5],
    ui.elevation[6],
    ui.elevation[7],
    ui.elevation[8],
    ui.elevation[9],
    ui.elevation[10],
    ui.elevation[11],
    ui.elevation[12],
    ui.elevation[13],
    ui.elevation[14],
    ui.elevation[15],
    ui.elevation[16],
    ui.elevation[17],
    ui.elevation[18],
    ui.elevation[19],
    ui.elevation[20],
    ui.elevation[21],
    ui.elevation[22],
    ui.elevation[23],
    ui.elevation[24],
  ],

  /****************************/
  /*** COMPONENTS OVERRIDES ***/
  /****************************/
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableTouchRipple: true,
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          borderRadius: ui.radius.md,
          backgroundColor: colors.white,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: ui.radius.md,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          borderRadius: ui.radius.button,
          boxShadow: 'none',
          '&:hover': {
            backgroundColor: colors.orange,
            boxShadow: 'none',
          },
        },
        outlinedPrimary: {
          border: `2px solid ${colors.orange}`,
          borderRadius: ui.radius.button,
          '&:hover': {
            border: `2px solid ${colors.orange}`,
            backgroundColor: colors.white,
          },
        },
      },
    },
  },
});

export default muiTheme;

import React, { useCallback } from 'react';
import { Trans } from '@lingui/react';
import { useKeycloakState } from 'keycloak-helper';
import { Menu, styled } from '@mui/material';
import {
  colors,
  layout,
  typography,
  ui,
  deprAxtTheme,
} from 'portal-design-system';
import config from '../../../../utils/config';
import LanguageToggle from './LanguageToggle/LanguageToggle';
import MenuEntry from './MenuEntry';

type Props = {
  anchorEl: any;
  onClose: (e: any) => void;
  email?: string;
  firstName?: string;
  lastName?: string;
};

const Container = styled('div')({
  minWidth: 400,
  padding: layout.spacing(1, 2),
  fontFamily: typography.fontFamily.primary,
});

const Title = styled('div')({
  fontWeight: typography.fontWeight.medium,
  fontSize: typography.fontSize.xl,
  marginBottom: layout.spacing(1),
});

const LanguageSelection = styled('div')({
  display: 'flex',
  margin: layout.spacing(2, 0),
});

const accentColor =
  config.portalTheme === 'AXT'
    ? deprAxtTheme.palette.primary.main
    : colors.orange;

const Logout = styled('button')({
  color: accentColor,
  backgroundColor: colors.white,
  border: `2px solid ${accentColor}`,
  borderRadius: ui.radius.button,
  width: '100%',
  height: layout['size-10'],
  fontSize: typography.fontSize.lg,
  fontWeight: typography.fontWeight.normal,
  margin: layout.spacing(1, 0),
  cursor: 'pointer',
});

function AccountMenu({ anchorEl, onClose, email, firstName, lastName }: Props) {
  const { keycloak } = useKeycloakState();

  const onLogout = useCallback(() => {
    keycloak.logout();
  }, [keycloak]);

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      <Container>
        {(firstName || lastName || email) && (
          <Title>
            <Trans id="accountMenu.profile" />
          </Title>
        )}
        <MenuEntry label="firstName" value={firstName} />
        <MenuEntry label="lastName" value={lastName} />
        <MenuEntry label="email" value={email} />
        {config.portalVariant === 'AXT' && (
          <LanguageSelection>
            <Title>
              <Trans id="accountMenu.language" />
            </Title>
            <LanguageToggle />
          </LanguageSelection>
        )}
        <Logout type="button" onClick={onLogout}>
          <Trans id="header.logout" />
        </Logout>
      </Container>
    </Menu>
  );
}

export default AccountMenu;

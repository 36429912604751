import React, { Component } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { handleError } from 'utils/sentry';

type State = {
  hasError: boolean;
};

type Props = any;

class PortalErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error) {
    handleError(error, 'portal');
  }

  render() {
    if (this.state.hasError) {
      return (
        <h1 style={{ display: 'flex', alignItems: 'center', padding: '1rem' }}>
          <ErrorIcon fontSize="large" style={{ marginRight: '1rem' }} />
          <p>The portal has encountered an error.</p>
        </h1>
      );
    }
    return this.props.children;
  }
}

export default PortalErrorBoundary;

import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useReducer,
} from 'react';
import { Snackbar } from '@mui/material';
import AxtNotification, { Status } from '../AxtNotification';

type VerticalPosition = 'top' | 'bottom';
type HorizontalPosition = 'center' | 'left' | 'right';

type ShowNotificationPayload = {
  message: string;
  status: Status;
  vertical?: VerticalPosition;
  horizontal?: HorizontalPosition;
  autoHideDuration?: number;
};

enum NotificationActionType {
  SHOW_NOTIFICATION = 'SHOW_NOTIFICATION',
  CLOSE_NOTIFICATION = 'CLOSE_NOTIFICATION',
}

type NotificationAction =
  | {
      type: NotificationActionType.SHOW_NOTIFICATION;
      payload: ShowNotificationPayload;
    }
  | { type: NotificationActionType.CLOSE_NOTIFICATION };

type NotificationContextState = {
  show: boolean;
  message: string;
  status: Status;
  vertical: VerticalPosition;
  horizontal: HorizontalPosition;
  autoHideDuration?: number;
};

const initialState: NotificationContextState = {
  show: false,
  message: '',
  status: 'info',
  vertical: 'top',
  horizontal: 'center',
  autoHideDuration: undefined,
};

function reducer(
  state: NotificationContextState,
  action: NotificationAction
): NotificationContextState {
  switch (action.type) {
    case NotificationActionType.SHOW_NOTIFICATION:
      return {
        ...state,
        // avoid displaying empty strings
        show: Boolean(action.payload.message),
        message: action.payload.message,
        status: action.payload.status,
        vertical: action.payload.vertical || state.vertical,
        horizontal: action.payload.horizontal || state.horizontal,
        autoHideDuration: action.payload.autoHideDuration,
      };
    case NotificationActionType.CLOSE_NOTIFICATION:
      return { ...state, show: false };
    default:
      return state;
  }
}

const NotificationContext = createContext({
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  dispatch: ((action: NotificationAction) =>
    0) as React.Dispatch<NotificationAction>,
  state: initialState,
});

function NotificationManager() {
  const {
    state: { show, message, status, vertical, horizontal, autoHideDuration },
    dispatch,
  } = useContext(NotificationContext);
  const handleClose = useCallback(() => {
    dispatch({ type: NotificationActionType.CLOSE_NOTIFICATION });
  }, [dispatch]);
  return (
    <Snackbar
      open={show}
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <AxtNotification elevation={6} status={status} onClose={handleClose}>
        {message}
      </AxtNotification>
    </Snackbar>
  );
}

export function useAxtNotification() {
  const { dispatch } = useContext(NotificationContext);
  return useCallback(
    (payload: ShowNotificationPayload) => {
      dispatch({
        type: NotificationActionType.SHOW_NOTIFICATION,
        payload,
      });
    },
    [dispatch]
  );
}

type AxtNotificationProviderProps = {
  children: ReactElement;
};

function AxtNotificationProvider({ children }: AxtNotificationProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <NotificationContext.Provider value={value}>
      <NotificationManager />
      {children}
    </NotificationContext.Provider>
  );
}

export default AxtNotificationProvider;

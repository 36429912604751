import React from 'react';
import clsx from 'clsx';
import {
  TextField,
  FilledTextFieldProps,
  OutlinedInputProps,
  Theme,
  alpha,
} from '@mui/material';
// /!\ @mui/styles IS DEPRECATED
import { makeStyles, createStyles } from '@mui/styles';

// TextField style
const useAxtTextFieldStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderColor: theme.palette.grey[300],
      borderWidth: '1px',
      borderStyle: 'solid',
      overflow: 'hidden',
      backgroundColor: theme.palette.common.white,
      transition: theme.transitions.create(['border-color', 'box-shadow'], {
        duration: theme.transitions.duration.complex,
      }),
      '&:hover': {
        backgroundColor: theme.palette.common.white,
      },
      '& .Mui-disabled': {
        backgroundColor: theme.palette.grey[300],
      },
      '&$focused': {
        backgroundColor: theme.palette.common.white,
        boxShadow: `${alpha(theme.palette.primary.main, 0.6)} 0 0 0 1px`,
        borderColor: theme.palette.primary.main,
      },
    },
    focused: {},
    error: {
      borderColor: theme.palette.error.main,
      '&$focused': {
        boxShadow: `${alpha(theme.palette.error.main, 0.6)} 0 0 0 1px`,
        borderColor: theme.palette.error.main,
      },
    },
  })
);

// Restrict color and variant props types
export type AxtTextFieldProps = React.HTMLAttributes<HTMLInputElement> &
  Omit<FilledTextFieldProps, 'variant'> & {
    css?: '';
  };

// TextField component
const AxtTextField: React.ForwardRefExoticComponent<AxtTextFieldProps> &
  React.RefAttributes<any> = React.forwardRef(function AxtTextField(
  { InputProps, ...props }: AxtTextFieldProps,
  ref: React.Ref<any>
) {
  const classes = useAxtTextFieldStyle();
  return (
    <TextField
      className={clsx(classes.error, props.error)}
      InputProps={{
        ...({ classes, disableUnderline: true } as Partial<OutlinedInputProps>),
        ...InputProps,
      }}
      ref={ref}
      {...props}
      variant="filled"
    />
  );
});

export default AxtTextField;

import React from 'react';
import clsx from 'clsx';
import { Paper, Theme, PaperProps } from '@mui/material';
// /!\ @mui/styles IS DEPRECATED
import { makeStyles, createStyles } from '@mui/styles';

const useStyle = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        padding: theme.spacing(3),
        backgroundColor: 'inherit',
      },
    }),
  { name: 'StepperStyle' }
);

type StepperProps = PaperProps & {
  activeStep?: number;
  children: React.ReactElement[];
};

function Stepper(
  { activeStep = 0, children, ...other }: StepperProps,
  ref: React.Ref<HTMLDivElement>
) {
  const classes = useStyle();
  const childrenArray = React.Children.toArray(children);
  const steps = childrenArray.map((step: any, index) => {
    const controlProps = {
      last: index + 1 === childrenArray.length,
    };

    const state = {
      index,
      active: false,
      completed: false,
      disabled: false,
    };

    if (activeStep === index) {
      state.active = true;
    } else if (activeStep > index) {
      state.completed = true;
    } else if (activeStep < index) {
      state.disabled = true;
    }

    return [
      React.cloneElement(step, { ...controlProps, ...state, ...step.props }),
    ];
  });

  return (
    <Paper
      square
      elevation={0}
      className={clsx(classes.root)}
      ref={ref}
      {...other}
    >
      {steps}
    </Paper>
  );
}

export default React.forwardRef(Stepper);

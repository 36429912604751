import { IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';

const colorStyle = (theme: any) => ({
  colorPrimary: {
    '&:hover': {
      backgroundColor: theme.palette.primary[50],
    },
    '&:active': {
      backgroundColor: theme.palette.primary[100],
    },
  },
  colorSecondary: {
    '&:hover': {
      backgroundColor: theme.palette.secondary[100],
    },
    '&:active': {
      backgroundColor: theme.palette.secondary[200],
    },
  },
});

const AxtIconBtn = withStyles((theme) => ({
  root: {
    transition: theme.transitions.create(['background-color'], {
      duration: theme.transitions.duration.short,
    }),
    '&:disabled': {
      pointerEvents: 'auto',
      cursor: 'not-allowed',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  ...colorStyle(theme),
}))(IconButton) as typeof IconButton;

export default AxtIconBtn;

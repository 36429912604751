import React from 'react';
import { Trans } from '@lingui/macro';
import { styled, Grid, Typography } from '@mui/material';
import { ReactComponent as ErrorImage } from '../../assets/not_found.svg';

const ImageWrapper = styled('div')(({ theme }) => ({
  '>*': {
    width: '100%',
    height: 'auto',
    padding: '20px 10px',
    fill: theme.palette.primary.light,
  },
}));

function Forbidden() {
  return (
    <Grid container direction="column" wrap="nowrap" alignItems="center">
      <Grid item sx={{ textAlign: 'center', maxWidth: '400px' }}>
        <ImageWrapper>
          <ErrorImage />
        </ImageWrapper>
        <Typography variant="h6">
          <Trans>Access Denied</Trans>
        </Typography>
        <Typography variant="body1">
          <Trans>
            You don&apos;t have the permission to access this content.
          </Trans>
        </Typography>
      </Grid>
      <Grid item />
    </Grid>
  );
}

export default Forbidden;

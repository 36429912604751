import { lazy } from 'react';
import config from 'utils/config';
import { AppConfig } from 'types';

const cisBoUrl = config.axt?.cisBoUrl;

// Tabs will appear in the header in the same order
export const AXT_PORTAL_CONFIG: AppConfig[] = [
  {
    appName: 'Dashboard IDcheck',
    appPath: 'cis-back-office',
    appComponent: lazy(() => import('ariadnext-portal-cis-back-office')),
    appProps: {
      cisBoUrl,
    },
    accessPermission: {
      resource: config.resources?.portal || 'axt-app-portal-front',
      resourceRoles: [/^FOLDER_EXPLORER$/],
    },
    testId: 'dashboardNavigation',
  },
  // {
  //   appName: 'Analytics',
  //   appPath: 'analytics',
  //   appComponent: lazy(() => import('portal-analytics')),
  //   accessPermission: {
  //     realmRoles: [/^customerportal_.*_AnalyticsManager$/],
  //   },
  //   testId: 'analyticsNavigation'
  // },
  {
    appName: 'Configuration',
    appPath: 'customer-conf',
    appComponent: lazy(() => import('ariadnext-portal-customer-conf')),
    appProps: {
      basePath: '/:realm/customer-conf',
      portalTheme: config.portalTheme,
    },
    accessPermission: {
      resource:
        config.resources?.customerConfiguration ||
        'axt-api-customer-configuration',
      resourceRoles: [/^WORKSPACE$/],
    },
    testId: 'configurationNavigation',
  },
  {
    appName: 'link sender',
    appPath: 'link-sender',
    appComponent: lazy(() => import('ariadnext-portal-playground')),
    appProps: {
      portalTheme: config.portalTheme,
      cisBoUrl,
    },
    accessPermission: {
      resource: config.resources?.sdkweb || 'axt-api-sdk-web',
      resourceRoles: [/^ROLE_SDK_ACCESS$/],
    },
    testId: 'linkSenderNavigation',
  },
  // {
  //   appName: 'check factory',
  //   appPath: 'check-factory',
  //   appComponent: lazy(() => import('ariadnext-portal-idcheck-check-factory')),
  //   accessPermission: {
  //     resource: 'portal-app',
  //     resourceRoles: [/^CHECK_FACTORY$/],
  //   },
  // },
  // {
  //   appName: 'folder explorer',
  //   appPath: 'folder-explorer',
  //   appComponent: lazy(
  //     () => import('ariadnext-portal-idcheck-folder-explorer')
  //   ),
  //   accessPermission: {
  //     resource: 'portal-app',
  //     resourceRoles: [/^FOLDER_EXPLORER$/],
  //   },
  // },
];

export const AXT_DEFAULT_TAB = 2;
export const AXT_DEFAULT_APP_PATH = AXT_PORTAL_CONFIG[AXT_DEFAULT_TAB].appPath;

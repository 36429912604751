import React from 'react';
import { InputBase, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import InputSearch from './AxtInputSearch';
import InputClear from './AxtInputClear';

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    flex: 1,
    width: '300px',
    marginLeft: theme.spacing(1),
  },
}));

type AxtSearchInputProps = {
  fieldName: string;
  value: string;
  placeholder?: string;
  clearTooltipTextContent: JSX.Element | string;
  searchTooltipTextContent: JSX.Element | string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  onSubmit: () => void;
};

function AxtSearchInput({
  fieldName,
  value,
  placeholder,
  clearTooltipTextContent,
  searchTooltipTextContent,
  onClear,
  onChange,
  onSubmit,
}: AxtSearchInputProps) {
  const classes = useStyles();
  return (
    <InputBase
      className={classes.input}
      name={fieldName}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      startAdornment={
        <InputSearch
          size="medium"
          onSubmit={onSubmit}
          tooltipTextContent={searchTooltipTextContent}
        />
      }
      endAdornment={
        value ? (
          <InputClear
            size="medium"
            tooltipTextContent={clearTooltipTextContent}
            onClear={onClear}
          />
        ) : null
      }
    />
  );
}

export default AxtSearchInput;

import { spacing, SPACING_SCALING_FACTOR } from '../utils/spacing';

/****************************/
/******** TYPOGRAPHY*********/
/****************************/
export const typography = {
  /*  Font Families */
  fontFamily: {
    primary: 'Wotfard',
  },

  /* Font Size */
  // Consistent typography sizing across an interface. Sizes are set in rem units to support proper font scaling, and assume a 16px root font-size.
  fontSize: {
    xxxs: '0.5rem', // 8px
    xxs: '0.625rem', // 10px
    xs: '0.75rem', // 12px
    sm: '0.875rem', // 14px
    base: '1rem', // 16px
    lg: '1.125rem', // 18px (h6)
    xl: '1.25rem', // 20px (h5)
    xxl: '1.5rem', // 24px (h4)
    xxxl: '1.75rem', // 28px (h3)
    xxxxl: '2rem', // 32px (h2)
    xxxxxl: '2.375rem', // 38px (h1)
  },

  /* Font Weight */
  // Consistent font weights across an interface
  fontWeight: {
    thin: 100,
    extralight: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },

  /* Line Height */
  // Encourage consistent line heights across an interface, applied as unitless values to scale with font size.
  lineHeight: {
    none: 1,
    xs: 1.125,
    sm: 1.275,
    md: 1.5,
    lg: 1.625,
    xl: 2,
  },

  /* Letter Spacing */
  // Encourage consistent letter spacing across an interface, applied as em units relative to the text's size */
  letterSpacing: {
    xxs: '-0.075em',
    xs: '-0.05em',
    sm: '-0.025em',
    none: '0em',
    lg: '0.025em',
    xl: '0.05em',
  },

  /* Prose Width (max-width)
  // Max-widths optimised for legibility of large blocks of text, based on the font and font-size of content. */
  proseWidth: {
    xs: '45ch',
    sm: '55ch',
    md: '65ch',
    lg: '75ch',
    xl: '85ch',
  },
};

/****************************/
/********** COLORS **********/
/****************************/
// Colors in HSL format
//  HUE           color in a 360° circle
//  SATURATION    from grey (0%) to full color (100%)
//  LIGHT         from black (0%) to white (100%)
export const colors = {
  black: 'hsl(0, 0%, 0%)', // #000000
  black12: 'hsla(0, 0%, 0%, 12%)',
  white: 'hsl(0, 0%, 100%)', // #FFFFFF
  grey96: 'hsl(0, 0%, 96%)', // #F5F5F5
  grey93: 'hsl(0, 0%, 93%)', // #EEEEEE
  grey90: 'hsl(0, 0%, 90%)', // #E5E5E5
  grey48: 'hsl(0, 0%, 48%)', // #7B7B7B
  grey27: 'hsl(0, 0%, 27%)', // #464646
  greyish: 'hsl(24, 4%, 78%)', // #C9C6C4
  greyish2: 'hsl(211, 25%, 84%)', // used in footer
  greyish3: 'hsl(340, 2%, 66%)', // used in header menu
  whitish: 'hsl(17, 100%, 96%)', // #FFF2ED
  yellow: 'hsl(39, 92%, 61%)', // #F7B53E
  lightOrange: 'hsl(14, 100%, 63%)', // #FF6B40
  orange: 'hsl(20, 93%, 53%)', // #F76419
  red: 'hsl(0, 65%, 57%)', // #D94A4A
  brightTurquoise: 'hsl(170, 83%, 54%)', // #28EBCA
  lightTurquoise: 'hsl(179, 40%, 65%)', // #4EB3B2
  turquoise: 'hsl(179, 40%, 50%)', // #4EB3B2
  lightBlue: 'hsl(191, 100%, 85%)', // #B1F1FF
  blue: 'hsl(209, 100%, 64%)', // #47A7FF
  darkBlue: 'hsl(220, 47%, 21%)', // #1C2C4D
  extraDarkBlue: 'hsl(236, 34%, 17%)', // #1C1E39
};

/****************************/
/********** LAYOUT **********/
/****************************/
export const layout = {
  /* Size Scale and Scaling Factor */
  // Encourage consistent spacing throughout an interface.
  spacingScalingFactor: SPACING_SCALING_FACTOR,
  spacing,
  'size-1': '4px',
  'size-2': '8px',
  'size-3': '12px',
  'size-4': '16px',
  'size-5': '20px',
  'size-6': '24px',
  'size-7': '28px',
  'size-8': '32px',
  'size-9': '36px',
  'size-10': '40px',
  'size-11': '44px',
  'size-12': '48px',
  'size-14': '56px',
  'size-16': '64px',
  'size-20': '80px',
  'size-24': '96px',
  'size-28': '112px',
  'size-32': '128px',
  'size-36': '144px',
  'size-40': '160px',
  'size-44': '176px',
  'size-48': '192px',
  'size-52': '208px',
  'size-56': '224px',
  'size-60': '240px',
  'size-64': '256px',
  'size-72': '288px',
  'size-80': '320px',
  'size-96': '384px',
  'size-px': '1px',
  'size-full': '100%',
  'size-screen': '100vw',
  'size-min': 'min-content',
  'size-max': 'max-content',

  /* Container Widths */
  // Encourage consistent max-widths for containers throughout an interface, based roughly on common device breakpoints
  maxWidth: {
    xs: '480px',
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
  },
};

/****************************/
/************ UI ************/
/****************************/
export const ui = {
  /* Radius */
  // Consistent edge radiuses throughout an interface.
  radius: {
    xs: 4,
    sm: 6,
    md: 8,
    lg: 12,
    xl: 16,
    button: 25,
    full: 9999,
  },

  /* Layers (z-index) */
  // Consistent layering throughout an interface
  layer: {
    1: 1000,
    2: 1050,
    3: 1100,
    4: 1200,
    5: 1300,
    6: 1400,
    7: 1500,
    below: -1,
    top: 9999,
  },

  /* Elevation */
  // Box shadows for creating realistic elevation in 3d space.
  elevation: {
    1: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    2: '0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)',
    3: '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    4: '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    5: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    6: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    7: '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    8: '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    9: '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    10: '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    11: '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    12: '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    13: '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    14: '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    15: '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    16: '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    17: '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    18: '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    19: '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    20: '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    21: '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    22: '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    23: '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    24: '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  },

  /* Blur */
  // Backdrop blur effects for giving a sense of depth to an interface
  blur: {
    xs: 'blur(4px)',
    sm: 'blur(8px)',
    md: 'blur(16px)',
    lg: 'blur(24px)',
    xl: 'blur(40px)',
  },

  /* Transitions & Animations */
  // Easing functions for realistic movement in transitions and animations. Inspired by the Material Design guidelines on motion.*/
  easing: {
    standard: 'cubic-bezier(0.4, 0, 0.2, 1)',
    accelerate: 'cubic-bezier(0.4, 0, 1, 1)',
    decelerate: 'cubic-bezier(0, 0, 0.2, 1)',
  },
};

/****************************/
/*********** GRID ***********/
/****************************/

/* The Grid System (grid-template-columns, grid-column )*/
export const grid = {
  'template-2': 'repeat(2, minmax(0, 1fr))',
  'template-3': 'repeat(3, minmax(0, 1fr))',
  'template-4': 'repeat(4, minmax(0, 1fr))',
  'template-5': 'repeat(5, minmax(0, 1fr))',
  'template-6': 'repeat(6, minmax(0, 1fr))',
  'template-7': 'repeat(7, minmax(0, 1fr))',
  'template-8': 'repeat(8, minmax(0, 1fr))',
  'template-9': 'repeat(9, minmax(0, 1fr))',
  'template-10': 'repeat(10, minmax(0, 1fr))',
  'template-11': 'repeat(11, minmax(0, 1fr))',
  'template-12': 'repeat(12, minmax(0, 1fr))',
};

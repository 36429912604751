import React from 'react';
import { Checkbox, CheckboxProps } from '@mui/material';
import CheckBoxSharpIcon from '@mui/icons-material/CheckBoxSharp';
import CheckBoxOutlineBlankSharpIcon from '@mui/icons-material/CheckBoxOutlineBlankSharp';

function AxtCheckbox(props: CheckboxProps) {
  return (
    <Checkbox
      icon={<CheckBoxOutlineBlankSharpIcon />}
      checkedIcon={<CheckBoxSharpIcon />}
      {...props}
    />
  );
}

export default AxtCheckbox;

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';
import { KeycloakInstance, KeycloakProfile } from 'keycloak-js';
import { useKeycloak } from '@react-keycloak/web';

type KeycloakState = {
  initialized: boolean;
  keycloak: KeycloakInstance;
  identityProvider?: string;
  profile?: KeycloakProfile & { name?: string };
};

/************************* */
/* Context
/************************* */

const KeycloakStateContext = createContext<KeycloakState | undefined>(
  undefined
);
KeycloakStateContext.displayName = 'KeycloakStateContext';

/************************* */
/* Context Poviders
/************************* */

type Props = {
  children: ReactNode;
  identityProvider?: string;
};

const KeycloakStateProvider = ({ children, identityProvider }: Props) => {
  const { initialized, keycloak } = useKeycloak();
  const [keycloakProfile, setKeycloakProfile] = useState<KeycloakProfile>({});

  useEffect(() => {
    keycloak.loadUserProfile().then((data) => {
      setKeycloakProfile(data);
    });
  }, [keycloak]);

  const tokenParsed = keycloak?.tokenParsed as any;

  return (
    <KeycloakStateContext.Provider
      value={{
        initialized,
        keycloak,
        identityProvider,
        profile: {
          ...keycloakProfile,
          name: tokenParsed ? tokenParsed.name : '',
        },
      }}
    >
      {children}
    </KeycloakStateContext.Provider>
  );
};

/************************* */
/*  Context Consumer
/************************* */

function useKeycloakState(): KeycloakState {
  const context = useContext(KeycloakStateContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a KeycloakWrapper');
  }
  return context;
}

// Export type, provider and consumer
export { KeycloakState, KeycloakStateProvider, useKeycloakState };

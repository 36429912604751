import React from 'react';
import { Trans } from '@lingui/react';
import { Link } from 'react-router-dom';
import { styled, Link as MuiLink, Typography } from '@mui/material';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { typography } from '../../design-tokens';

const Container = styled('div')(({ theme }) => ({
  minHeight: '120px',
  padding: theme.spacing(1, 2),
}));

const BackButtonSkeleton = styled('div')({
  minHeight: '24px',
});

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  flex: '1 1 auto',
  padding: theme.spacing(1),
}));

type Props = {
  title: string;
  titleValues?: Record<string, unknown>;
  backButtonRoute?: string;
  backButtonLabel?: string;
  children?: React.ReactNode;
};

const AppHeader = ({
  title,
  titleValues,
  backButtonRoute,
  backButtonLabel,
  children,
}: Props) => {
  return (
    <Container>
      {backButtonRoute && backButtonLabel ? (
        <MuiLink
          component={Link}
          to={backButtonRoute}
          underline="hover"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <ArrowBackIosRoundedIcon
            sx={{ fontSize: typography.fontSize.sm, marginRight: 1 }}
          />
          <Typography variant="body1" component="span" color="primary">
            <Trans id={backButtonLabel} />
          </Typography>
        </MuiLink>
      ) : (
        <BackButtonSkeleton />
      )}
      <Content>
        <Typography variant="h2" color="textSecondary" display="inline">
          <Trans id={title} values={titleValues} />
        </Typography>
        {children}
      </Content>
    </Container>
  );
};

export default AppHeader;

import React from 'react';
import { Theme, ModalProps, Modal, Box, IconButton } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    container: {
      outline: 0,
      backgroundColor: theme.palette.common.white,
      width: 'fit-content',
    },
    header: {
      padding: theme.spacing(3),
      backgroundColor: theme.palette.grey[200],
    },
    content: {
      padding: theme.spacing(4, 3, 3, 3),
    },
    titleSkeleton: {
      width: '100px',
    },
  })
);

export type AxtModalProps = Omit<ModalProps, 'open' | 'children' | 'title'> & {
  children: React.ReactNode;
  title?: JSX.Element | string;
  isOpen: boolean;
  onClose: () => void;
};

function AxtModal({
  children,
  title,
  isOpen,
  onClose,
  ...other
}: AxtModalProps) {
  const classes = useStyles();

  return (
    <Modal className={classes.root} open={isOpen} onClose={onClose} {...other}>
      <div className={classes.container}>
        <Box
          className={classes.header}
          display="flex"
          justifyContent="space-between"
        >
          {title || <div className={classes.titleSkeleton} />}
          <IconButton color="secondary" size="small" onClick={onClose}>
            <CloseSharpIcon />
          </IconButton>
        </Box>
        <div className={classes.content}>{children}</div>
      </div>
    </Modal>
  );
}

export default AxtModal;

import React, { useCallback, useState } from 'react';
import { styled } from '@mui/material';
import { colors, typography, layout } from 'portal-design-system';
import { usePortalContext } from 'context';
import { User } from 'types';
import AccountMenu from './AccountMenu';

function getUserDetails(user: User) {
  const { username, givenName, familyName } = user;
  if (givenName && familyName) {
    return [
      `${givenName} ${familyName}`,
      `${givenName.substring(0, 1)} ${familyName.substring(0, 1)}`,
    ];
  }
  if (username) {
    return [username, username.substring(0, 1)];
  }
  return ['', ''];
}

const UserSection = styled('div')({
  display: 'flex',
  alignItems: 'center',
  fontFamily: typography.fontFamily.primary,
});

const UserInformation = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
  paddingRight: layout.spacing(1),
});

const UserAvatar = styled('div')({
  width: 50,
  height: 50,
  borderRadius: '50%',
  backgroundColor: colors.grey90,
  cursor: 'pointer',
  userSelect: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontWeight: typography.fontWeight.medium,
  fontSize: typography.fontSize.xl,
  letterSpacing: typography.letterSpacing.xxs,
});

function AccountSection() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [{ user }] = usePortalContext();

  const onOpen = useCallback(
    (e) => {
      setAnchorEl(e.currentTarget);
    },
    [setAnchorEl]
  );

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);

  if (!user) {
    return null;
  }

  const { email, givenName, familyName } = user;
  const [name, initials] = getUserDetails(user);

  return (
    <>
      <UserSection>
        <UserInformation>
          <span
            style={{
              fontSize: typography.fontSize.lg,
              fontWeight: typography.fontWeight.medium,
            }}
          >
            {name}
          </span>
          <span
            style={{ fontSize: typography.fontSize.sm, color: colors.grey48 }}
          >
            {email}
          </span>
        </UserInformation>
        <UserAvatar
          role="button"
          tabIndex={0}
          onClick={onOpen}
          onKeyDown={onOpen}
          id="headerAvatar"
        >
          {initials}
        </UserAvatar>
      </UserSection>
      <AccountMenu
        anchorEl={anchorEl}
        onClose={onClose}
        email={email}
        firstName={givenName}
        lastName={familyName}
      />
    </>
  );
}

export default AccountSection;

import React from 'react';
import { styled, Button } from '@mui/material';
import { DateRange } from 'react-day-picker';
import { Trans } from '@lingui/react';
import { formatDateRange } from '../../../utils/dateRange';
import { layout, typography } from '../../../design-tokens';

const Container = styled('div')({
  gridColumn: '2 / 2',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: layout.spacing(2),
});

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'end',
  '& button:first-of-type': {
    marginRight: layout.spacing(2),
  },
});

const Range = styled('span')({
  fontSize: typography.fontSize.sm,
  fontStyle: 'italic',
});

type Props = {
  handleApply: () => void;
  handleCancel: () => void;
  calendarRange: DateRange | undefined;
};

function isApplyDisabled(range?: DateRange) {
  return !range || !range.from || !range.to;
}

function BottomBar({ handleApply, handleCancel, calendarRange }: Props) {
  return (
    <Container>
      <Range>
        <Trans id="designSystem.dateRangePicker.selectedDate" />
        {` ${formatDateRange(calendarRange)}`}
      </Range>
      <ButtonWrapper>
        <Button variant="outlined" onClick={handleCancel}>
          <Trans id="designSystem.dateRangePicker.cancel" />
        </Button>
        <Button
          variant="contained"
          onClick={handleApply}
          disabled={isApplyDisabled(calendarRange)}
        >
          <Trans id="designSystem.dateRangePicker.apply" />
        </Button>
      </ButtonWrapper>
    </Container>
  );
}

export default BottomBar;

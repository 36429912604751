import { getIdpHint } from 'keycloak-helper';

declare const window: any;

type PortalVariant = 'AXT' | 'IDN';

type KeyloackConf = {
  url: string;
  clientId: string;
  realm: string;
};

type Config = {
  portalVariant: PortalVariant;
  portalTheme: PortalVariant;
  keycloak: KeyloackConf;
  sentryUrl?: string;
  // Used when portalVariant = AXT
  axt?: {
    cisBoUrl: string;
  };
  // Used when portalVariant = IDN
  idn?: {
    myIdNowUrl: string;
  };
  // keycloak clients
  resources?: {
    portal?: string;
    customerConfiguration?: string;
    sdkweb?: string;
  };
};

function buildConfig(): Config {
  const config: Config = window.AriadnextPortalConfig || {};
  // IDN: real is dynamically retrieved from URL
  if (config.portalVariant === 'IDN') {
    config.keycloak.realm = getIdpHint();
  }
  return config;
}

const config = buildConfig();

export default config;

import React, { useState, useCallback, useEffect, ReactNode } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import {
  KeycloakInitOptions,
  KeycloakConfig,
  KeycloakInstance,
} from 'keycloak-js';
import { getKeycloakInstance } from '../keycloak';
import { getIdpHint } from '../utils/identityProvider';
import { KeycloakStateProvider } from './KeycloakStateContext';

type Props = {
  initOptions: KeycloakInitOptions;
  keycloakConfig: string | KeycloakConfig;
  children: ReactNode;
};

function KeycloakProvider({ initOptions, keycloakConfig, children }: Props) {
  const [instance, setInstance] = useState<KeycloakInstance | null>(null);
  const [authenticated, setAuthenticated] = useState(false);

  const idpHint = getIdpHint();

  // Create keycloak instance
  useEffect(() => {
    setInstance(getKeycloakInstance(keycloakConfig));
    // Keycloak is instanciated once and for all, we don't listen to config changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle keycloak event
  // Manage redirection to login when not 'login-required'
  const handleKeycloakEvent = useCallback(
    (eventType) => {
      if (!instance) return;

      if (instance.authenticated) {
        setAuthenticated(true);
      } else {
        if (initOptions.onLoad !== 'login-required') {
          // Redirect to the login page for a realm
          instance.login({ idpHint });
        }
        if (eventType === 'onTokenExpired') {
          instance.updateToken(30).catch(() => {
            instance.login({ idpHint });
          });
        }
      }
    },
    [initOptions.onLoad, instance, idpHint]
  );

  if (instance) {
    return (
      <ReactKeycloakProvider
        authClient={instance}
        initOptions={initOptions}
        onEvent={handleKeycloakEvent}
      >
        {authenticated && (
          <KeycloakStateProvider identityProvider={idpHint}>
            {children}
          </KeycloakStateProvider>
        )}
      </ReactKeycloakProvider>
    );
  }
  return null;
}

export default KeycloakProvider;

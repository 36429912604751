import React from 'react';
import { styled } from '@mui/material';
import { Trans } from '@lingui/react';
import { colors, layout, typography } from 'portal-design-system';

const Footer = styled('footer')({
  minHeight: 128,
  backgroundColor: colors.grey27,
  display: 'grid',
  padding: layout.spacing(2),
  gridTemplateColumns: '1fr 1fr 1fr',
  gridTemplateRows: `${layout.spacing(4)} 1fr`,
  columnGap: layout.spacing(4),
  fontSize: typography.fontSize.xs,
  color: colors.greyish2,
});

const Title = styled('div')({
  color: colors.white,
  fontSize: typography.fontSize.lg,
  fontWeight: typography.fontWeight.normal,
  justifySelf: 'center',
});

const Text = styled('p')({
  maxWidth: '500px',
  justifySelf: 'center',
});

const Content = styled('div')({
  maxWidth: 300,
  justifySelf: 'center',
});

const Label = styled('span')({
  color: colors.orange,
  fontWeight: typography.fontWeight.bold,
  marginRight: layout.spacing(1),
});

const Link = styled('a')({
  textDecoration: 'none',
  color: 'inherit',
  '&:hover': {
    textDecoration: 'underline',
  },
});

function IdnPortalFooter() {
  return (
    <Footer>
      <Title>
        <Trans id="footer.aboutIdnow" />
      </Title>
      <Title>
        <Trans id="footer.contactUs" />
      </Title>
      <Title>
        <Trans id="footer.reachUs" />
      </Title>
      <Text>
        <Trans id="footer.aboutIdnow.text" />
      </Text>
      <Content>
        <div>
          <Label>
            <Trans id="footer.address" />
          </Label>
          Auenstr. 100, 80469 Munich, Germany
        </div>
        <div>
          <Label>
            <Trans id="footer.phone" />
          </Label>
          +49 89 413 24 600
        </div>
        <div>
          <Label>
            <Trans id="footer.email" />
          </Label>
          <Link
            href="mailto:support@mail.idnow.de"
            target="_blank"
            rel="noopenener noreferrer"
          >
            support@mail.idnow.de
          </Link>
        </div>
        <div>
          <Label>
            <Trans id="footer.contactForm" />
          </Label>
          <Link
            href="https://www.idnow.io/kontakt"
            target="_blank"
            rel="noopenener noreferrer"
          >
            <Trans id="footer.contactForm.link" />
          </Link>
        </div>
      </Content>
      <Content>
        <div>
          <Label>
            <Trans id="footer.identCenter" />
          </Label>
          <Trans id="footer.identCenter.hours" />
        </div>
        <div>
          <Label>
            <Trans id="footer.sales" />
          </Label>
          <Trans id="footer.sales.hours" />
        </div>
        <div>
          <Label>
            <Trans id="footer.IT" />
          </Label>
          24/7
        </div>
      </Content>
    </Footer>
  );
}

export default IdnPortalFooter;

import config from 'utils/config';
import {
  AXT_PORTAL_CONFIG,
  AXT_DEFAULT_TAB,
  AXT_DEFAULT_APP_PATH,
} from './portal.axt.conf';
import {
  IDN_PORTAL_CONFIG,
  IDN_DEFAULT_TAB,
  IDN_DEFAULT_APP_PATH,
} from './portal.idn.conf';

export const PORTAL_CONFIG =
  config.portalVariant === 'AXT' ? AXT_PORTAL_CONFIG : IDN_PORTAL_CONFIG;
export const DEFAULT_TAB =
  config.portalVariant === 'AXT' ? AXT_DEFAULT_TAB : IDN_DEFAULT_TAB;
export const DEFAULT_APP_PATH =
  config.portalVariant === 'AXT' ? AXT_DEFAULT_APP_PATH : IDN_DEFAULT_APP_PATH;

import Keycloak, { KeycloakInstance, KeycloakConfig } from 'keycloak-js';

let keycloakInstance: KeycloakInstance | null = null;

export function getKeycloakInstance(keycloakConfig?: string | KeycloakConfig) {
  if (keycloakInstance) {
    // console.log('Return existing keycloak instance');
    return keycloakInstance;
  }
  // console.log('Instanciate and return keycloak instance: ', keycloakConfig);
  keycloakInstance = Keycloak(keycloakConfig);
  return keycloakInstance;
}

import { createContext, useContext } from 'react';

export const VariantContext = createContext<'IDN' | 'AXT'>('IDN');

export function useVariantContext() {
  const context = useContext(VariantContext);
  if (context === undefined) {
    throw new Error(
      'useVariantContext must be used within a VariantContext Provider'
    );
  }
  return context;
}

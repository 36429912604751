import React, { createContext, ReactNode, useContext, useReducer } from 'react';
import { PortalState } from 'types';
import { Dispatch } from './portal.actions';
import { initialState, portalReducer } from './portal.reducer';

/************************* */
/* Contexts
/************************* */
const PortalStateContext = createContext<PortalState | undefined>(undefined);
PortalStateContext.displayName = 'PortalState';

const PortalDispatchContext = createContext<Dispatch | undefined>(undefined);
PortalDispatchContext.displayName = 'PortalDispatch';

/************************* */
/* Context Poviders
/************************* */
type ProviderProps = { children: ReactNode };

function PortalContextProvider({ children }: ProviderProps) {
  const [state, dispatch] = useReducer(portalReducer, initialState);
  return (
    <PortalStateContext.Provider value={state}>
      <PortalDispatchContext.Provider value={dispatch}>
        {children}
      </PortalDispatchContext.Provider>
    </PortalStateContext.Provider>
  );
}

/************************* */
/*  Context Consumers
/************************* */
function usePortalState(): PortalState {
  const context = useContext(PortalStateContext);
  if (context === undefined) {
    throw new Error(
      'usePortalState must be used within a IdcheckAnalyticsContextProvider'
    );
  }
  return context;
}

function usePortalDispatch(): Dispatch {
  const context = useContext(PortalDispatchContext);
  if (context === undefined) {
    throw new Error(
      'usePortalDispatch must be used within a IdcheckAnalyticsContextProvider'
    );
  }
  return context;
}

function usePortalContext(): [PortalState, Dispatch] {
  return [usePortalState(), usePortalDispatch()];
}

/************************* */
/*  Exports
/************************* */

export {
  PortalContextProvider,
  usePortalState,
  usePortalDispatch,
  usePortalContext,
};

import React, { FC } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Switch, SwitchProps, Theme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 36,
      height: 18,
      padding: 0,
      display: 'flex',
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: '2px 4px',
      color: theme.palette.common.white,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
        },
      },
      '&$disabled': {
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.grey[300],
          borderColor: theme.palette.grey[400],
        },
      },
    },
    colorPrimary: {
      '&$checked': {
        '& + $track': {
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    colorSecondary: {
      '&$checked': {
        '& + $track': {
          backgroundColor: theme.palette.secondary.main,
          borderColor: theme.palette.secondary.main,
        },
      },
    },
    thumb: {
      width: 13,
      height: 13,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 20 / 2,
      opacity: 1,
      backgroundColor: theme.palette.grey[400],
    },
    checked: {},
    disabled: {},
    sizeSmall: {
      fontSize: '14px',
    },
  })
);

export type AxtSwitchProps = Exclude<SwitchProps, 'size' & 'color'> & {
  size?: 'medium';
  color?: 'secondary' | 'primary';
};

const AxtSwitch: FC<AxtSwitchProps> = ({ ...props }: AxtSwitchProps) => {
  const classes = useStyle();
  return (
    <Switch
      classes={classes}
      checkedIcon={<CheckCircleIcon className={classes.sizeSmall} />}
      {...props}
    />
  );
};

export default AxtSwitch;

import React, { Suspense, useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { usePortalState } from 'context';
import AppErrorBoundary from 'components/AppErrorBoundary';
import useDefaultAppPath from 'hooks/useDefaultAppPath';
import Forbidden from '../Forbidden/index';
import LazyLoadingFallback from './LazyLoadingFallback';

const Router = () => {
  const { portalApps, language } = usePortalState();
  const defaultAppPath = useDefaultAppPath();

  // If default path is not available, redirect to /
  const defaultPath = useMemo(() => {
    for (const app of portalApps) {
      if (app.appPath === defaultAppPath) {
        return app.appPath;
      }
    }
    return '/';
  }, [defaultAppPath, portalApps]);

  return (
    <Suspense fallback={<LazyLoadingFallback />}>
      <Switch>
        {portalApps.map((app) => {
          const { appComponent, appPath, appProps, appName } = app;
          const Component = appComponent;
          return (
            <Route
              key={appPath}
              path={appPath}
              render={() => (
                <AppErrorBoundary appName={appName}>
                  <Component language={language} {...appProps} />
                </AppErrorBoundary>
              )}
            />
          );
        })}
        <Route render={() => <Redirect to={defaultPath} />} />
        <Route path="/forbidden" render={() => <Forbidden />} />
      </Switch>
    </Suspense>
  );
};

export default Router;

import React, { useEffect } from 'react';
import {
  KeycloakProvider,
  useKeycloakState,
  KeycloakInitOptions,
} from 'keycloak-helper';
import config from 'utils/config';
import { usePortalContext } from 'context';
import Error404 from 'components/Error404';

const axtInitOptions: KeycloakInitOptions = {
  checkLoginIframe: false,
};

const idnInitOptions: KeycloakInitOptions = {
  pkceMethod: 'S256',
  checkLoginIframe: true,
};

type AuthGuardianProps = {
  children: any;
};

function AuthGuardian({ children }: AuthGuardianProps) {
  const { keycloak, identityProvider } = useKeycloakState();
  const [{ user }, dispatch] = usePortalContext();

  useEffect(() => {
    if (keycloak.idTokenParsed && identityProvider) {
      const {
        preferred_username: username = '',
        family_name: familyName,
        given_name: givenName,
        email,
      } = keycloak.idTokenParsed as any;
      dispatch({
        type: 'LOGIN',
        payload: {
          user: {
            username,
            familyName,
            givenName,
            email,
          },
          identityProvider,
        },
      });
    }
  }, [keycloak.idTokenParsed, identityProvider, dispatch]);

  if (!identityProvider) {
    return <Error404 />;
  }
  if (!user) {
    return <div>Loading...</div>;
  }
  return children;
}

type AuthProvider = {
  children: any;
};

function AuthProvider({ children }: AuthProvider) {
  return (
    <KeycloakProvider
      initOptions={
        config.portalVariant === 'AXT' ? axtInitOptions : idnInitOptions
      }
      keycloakConfig={config.keycloak}
    >
      <AuthGuardian>{children}</AuthGuardian>
    </KeycloakProvider>
  );
}

export default AuthProvider;

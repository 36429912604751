import { getKeycloakInstance } from '../../keycloak';

export function atLeastOneMatch(strings: string[], regexps: RegExp[]) {
  return strings.some((s) => {
    return regexps.some((r) => {
      return s.match(r);
    });
  });
}

export function hasRealmRole(roles: RegExp[]) {
  const kc = getKeycloakInstance();
  const realmAccess = kc?.realmAccess;
  if (!realmAccess) {
    return false;
  }
  return atLeastOneMatch(realmAccess.roles, roles);
}

export function hasResourceRole(roles: RegExp[], resource: string) {
  const kc = getKeycloakInstance();
  const resourceAccess = kc?.resourceAccess?.[resource];
  if (!resourceAccess) {
    return false;
  }
  return atLeastOneMatch(resourceAccess.roles, roles);
}

import React, { useEffect, useState } from 'react';
import { I18nProvider as LinguiProvider } from '@lingui/react';
import { usePortalContext } from 'context';

type I18nLoaderProps = {
  children: React.ReactNode;
};

function I18nLoader({ children }: I18nLoaderProps) {
  const [{ language }] = usePortalContext();
  const [catalogs, setCatalogs] = useState<any>({});
  const [actualLanguage, setActualLanguage] = useState<string>(language);

  useEffect(() => {
    if (catalogs[language]) {
      setActualLanguage(language);
    }
  }, [language, catalogs]);

  useEffect(() => {
    const loadCatalog = async () => {
      // manually sanitize input
      // eslint-disable-next-line
      const messages = await import(
        /* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
        `../../locales/${language.replace(/[^a-z]/gim, '')}/messages.js`
      );

      setCatalogs({
        ...catalogs,
        [language]: messages.default,
      });
    };

    loadCatalog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return catalogs[actualLanguage] ? (
    <LinguiProvider language={actualLanguage} catalogs={catalogs}>
      {children}
    </LinguiProvider>
  ) : null;
}

export default I18nLoader;

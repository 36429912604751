import React from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
// /!\ @mui/styles IS DEPRECATED
import { makeStyles, createStyles } from '@mui/styles';

const useStyle = makeStyles(
  (theme: Theme) =>
    createStyles({
      container: {
        display: 'flex',
        alignItems: 'center',
        height: '1rem',
        width: '1rem',
      },
      root: {
        height: '0.8rem',
        width: '0.8rem',
        marginTop: '0.4rem',
        marginLeft: '0.1rem',
        marginRight: '0.1rem',
        lineHeight: '1.2rem',
        borderRadius: '50%',
        borderStyle: 'solid',
        borderColor: theme.palette.common.black,
        borderWidth: '1px',
      },
      active: {
        backgroundColor: theme.palette.common.black,
      },
      completed: {
        marginTop: '0.3rem',
        color: theme.palette.grey[800],
        zindex: '1',
        fontSize: 16,
      },
      disabled: {
        borderColor: theme.palette.grey[400],
        color: theme.palette.grey[400],
      },
      icon: {
        marginTop: '0.3rem',
        zindex: '1',
        fontSize: 16,
      },
    }),
  { name: 'AxtStepIconStyle' }
);

export interface StepIconProps extends React.HTMLAttributes<HTMLDivElement> {
  active?: boolean;
  completed?: boolean;
  disabled?: boolean;
  error?: boolean;
}

const StepIcon: React.FC<StepIconProps> = ({
  active,
  completed,
  disabled,
  error,
}) => {
  const classes = useStyle();
  return (
    <div className={classes.container}>
      {error ? (
        <ErrorIcon className={classes.icon} />
      ) : completed ? (
        <CheckCircleOutlinedIcon className={classes.icon} />
      ) : (
        <div
          className={clsx(classes.root, {
            [classes.active]: active,
            [classes.disabled]: disabled,
          })}
        />
      )}
    </div>
  );
};

export default StepIcon;

import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { Paper, PaperProps, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      padding: '0.5rem 0',
    },
  })
);

export type AxtListItemProps = Omit<PaperProps, 'square'>;

function AxtListItem({ children, ...other }: AxtListItemProps) {
  const classes = useStyles();

  return (
    <Paper square className={classes.root} {...other}>
      {children}
    </Paper>
  );
}

export default AxtListItem;

import { lazy } from 'react';
import config from 'utils/config';
import { AppConfig } from 'types';

const myIdNowUrl = config.idn?.myIdNowUrl;

// Tabs will appear in the header in the same order
export const IDN_PORTAL_CONFIG: AppConfig[] = [
  {
    appName: 'Dashboard AI & VI',
    appPath: 'dashboard',
    appComponent: lazy(() => import('portal-my-idnow-wrapper')),
    appProps: {
      url: `${myIdNowUrl}/dashboard`,
    },
    accessPermission: {
      realmRoles: [/^customerportal_.*_IdentReviewer$/],
    },
    testId: 'dashboardIntroNavigation',
  },
  {
    appName: 'Organization',
    appPath: 'organization',
    appComponent: lazy(() => import('portal-my-idnow-wrapper')),
    appProps: {
      url: `${myIdNowUrl}/organization`,
    },
    accessPermission: {
      realmRoles: [/^customerportal_.*_Admin$/, /^customerportal_.*_IDnowCS$/],
    },
    testId: 'organizationNavigation',
  },
  {
    appName: 'Customization',
    appPath: 'customization',
    appComponent: lazy(() => import('portal-my-idnow-wrapper')),
    appProps: {
      url: `${myIdNowUrl}/customization`,
    },
    accessPermission: {
      realmRoles: [/^customerportal_.*_FrontendDesigner$/],
    },
    testId: 'customizationNavigation',
  },
  // {
  //   appName: 'Analytics',
  //   appPath: 'analytics',
  //   appComponent: lazy(() => import('portal-analytics')),
  //   accessPermission: {
  //     realmRoles: [/^customerportal_.*_AnalyticsManager$/],
  //   },
  // testId: 'analyticsNavigation'
  // },
];

export const IDN_DEFAULT_TAB = 0;
export const IDN_DEFAULT_APP_PATH = IDN_PORTAL_CONFIG[IDN_DEFAULT_TAB].appPath;

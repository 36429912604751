import React from 'react';
import { Typography } from '@mui/material';

export type TooltipContentProps = {
  titleContent?: React.ReactNode;
  textContent: React.ReactNode;
};

export default function TooltipContent({
  titleContent,
  textContent,
}: TooltipContentProps) {
  return (
    <div>
      {titleContent && (
        <Typography variant="body1" color="primary" gutterBottom={true}>
          {titleContent}
        </Typography>
      )}
      <Typography variant="subtitle2" align="justify">
        {textContent}
      </Typography>
    </div>
  );
}

import React from 'react';
import { Button, ButtonProps, Theme, alpha } from '@mui/material';
// /!\ @mui/styles IS DEPRECATED
import { makeStyles, createStyles } from '@mui/styles';

const textStyle = (theme: Theme) => ({
  textPrimary: {
    borderRadius: 25,
    '&:hover': {
      backgroundColor: theme.palette.primary,
    },
    '&:active': {
      backgroundColor: theme.palette.primary,
    },
  },
  textSecondary: {
    '&:hover': {
      backgroundColor: theme.palette.secondary,
    },
    '&:active': {
      backgroundColor: theme.palette.secondary,
    },
  },
});

const outlinedStyle = (theme: Theme) => ({
  outlinedPrimary: {
    '&:hover': {
      backgroundColor: theme.palette.primary,
    },
    '&:active': {
      backgroundColor: theme.palette.primary,
      boxShadow: `${alpha(theme.palette.primary.main, 0.6)} 0 0 0 1px`,
    },
  },
  outlinedSecondary: {
    '&:hover': {
      backgroundColor: theme.palette.secondary,
    },
    '&:active': {
      backgroundColor: theme.palette.secondary,
      boxShadow: `${alpha(theme.palette.secondary.main, 0.6)} 0 0 0 1px`,
    },
  },
});

const containedStyle = (theme: Theme) => ({
  contained: {
    '&:active': {
      boxShadow: `${alpha(theme.palette.grey[700], 0.6)} 1px 1px 3px 1px`,
    },
  },
  containedPrimary: {
    '&:hover': {
      backgroundColor: theme.palette.primary,
    },
    '&:active': {
      backgroundColor: theme.palette.primary,
    },
  },
  containedSecondary: {
    '&:hover': {
      backgroundColor: theme.palette.secondary,
    },
    '&:active': {
      backgroundColor: theme.palette.secondary,
    },
  },
});

const style = (theme: Theme) => ({
  root: {
    transition: theme.transitions.create(['box-shadow', 'background-color'], {
      duration: theme.transitions.duration.short,
    }),
  },
  ...containedStyle(theme),
  ...outlinedStyle(theme),
  ...textStyle(theme),
});

const useStyles = makeStyles((theme: Theme) => createStyles(style(theme)));

function AxtButton({ children, ...props }: ButtonProps) {
  const classes = useStyles();
  return (
    <Button classes={classes} {...props}>
      {children}
    </Button>
  );
}

export default AxtButton;

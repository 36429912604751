import React from 'react';
import { Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

const useStyle = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        flex: '1 1 auto',
        padding: theme.spacing(1),
      },
    }),
  { name: 'AxtPageHeaderContentStyle' }
);

export type AxtPageHeaderContentProps = {
  children: React.ReactNode;
};

function AxtPageHeaderContent({ children }: AxtPageHeaderContentProps) {
  const classes = useStyle();

  return <div className={classes.root}>{children}</div>;
}

export default AxtPageHeaderContent;

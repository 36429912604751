import React from 'react';
import { useLocation } from 'react-router-dom';
import config from 'utils/config';
import AxtPortalFooter from './AxtPortalFooter';
import IdnPortalFooter from './IdnPortalFooter';

const { portalVariant } = config;

// Integrated tabs, i.e. tabs served inside an iframe (dashboard, organization, customizaton)
export const IDN_INTEGRATED_TAB = [
  'dashboard',
  'organization',
  'customization',
];

function PortalFooter() {
  const { pathname } = useLocation();

  if (portalVariant === 'AXT') {
    return <AxtPortalFooter />;
  }

  const currentApp = pathname.split('/').pop() || '';
  if (!IDN_INTEGRATED_TAB.includes(currentApp)) {
    // only add IDN footer if we are not inside the iframe
    return <IdnPortalFooter />;
  }
  return null;
}

export default PortalFooter;

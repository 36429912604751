import React from 'react';
import { makeStyles, createStyles } from '@mui/styles';
import { IconButton, Theme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AxtTooltip from '../../../AxtTooltip';
import AxtTooltipContent from '../../../AxtTooltipContent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      padding: theme.spacing(1),
    },
  })
);

type InputSearchProps = {
  onSubmit: (event: React.MouseEvent<HTMLButtonElement>) => void;
  tooltipTextContent: React.ReactNode;
  size?: 'small' | 'medium';
};

export default function InputSearch({
  onSubmit,
  tooltipTextContent,
  size,
}: InputSearchProps) {
  const classes = useStyles();

  return (
    <AxtTooltip
      aria-label="search-icon-button-tooltip"
      title={<AxtTooltipContent textContent={tooltipTextContent} />}
    >
      <IconButton
        aria-label="search-button"
        className={classes.iconButton}
        onClick={onSubmit}
        type="submit"
        size={size}
      >
        <SearchIcon fontSize={size === 'medium' ? 'inherit' : 'small'} />
      </IconButton>
    </AxtTooltip>
  );
}

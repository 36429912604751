import { hasResourceRole, hasRealmRole } from 'keycloak-helper';
import { PORTAL_CONFIG } from 'config/portal.conf';
import { AppConfig } from 'types';

function isPermissionNeeded(roles: RegExp[], realmRoles: RegExp[]) {
  return roles.length > 0 || realmRoles.length > 0;
}

export function getAuthorizedApps(identityProvider: string): AppConfig[] {
  const portalApps: AppConfig[] = [];
  for (const app of PORTAL_CONFIG) {
    // Check if we can access the app :
    // Check if no permission is needed
    // Check if the required realm role is in the token
    // Check if the required resource role is in the token for a specific resource (keycloak client)
    const {
      resource = '',
      resourceRoles = [],
      realmRoles = [],
    } = app.accessPermission;
    if (
      !isPermissionNeeded(resourceRoles, realmRoles) ||
      hasRealmRole(realmRoles) ||
      hasResourceRole(resourceRoles, resource)
    ) {
      portalApps.push({
        ...app,
        // Compute app path using the realm
        appPath: `/${identityProvider}/${app.appPath}`,
      });
    }
  }
  return portalApps;
}

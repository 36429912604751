import React, { useCallback } from 'react';
import { makeStyles, createStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Select, MenuItem, Typography, Grid, Theme } from '@mui/material';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';
import AxtIconButton from '../AxtIconButton';
import { useAxtPagination } from './useAxtPagination';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `0.5px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.common.white,
    },
    panel: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
    },
    text: {
      marginRight: theme.spacing(1),
    },
    navigationButton: {
      borderRadius: 0,
    },
  })
);

export type AxtPaginationProps = {
  itemsLabel: React.ReactNode;
  onChange: (data: { size: number; page: number }) => void;
  page: number;
  pageLabel: React.ReactNode;
  pageSizes?: number[];
  rangeLabel: React.ReactNode;
  pageSize: number;
  size?: 'small' | 'medium';
  totalItems: number;
};

export default function AxtPagination({
  itemsLabel,
  onChange,
  page,
  pageLabel,
  pageSizes = [10, 25, 50, 100],
  rangeLabel,
  pageSize,
  size = 'small',
  totalItems,
}: AxtPaginationProps) {
  const classes = useStyles();
  const theme: Theme = useTheme();
  const isScreenWiderThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const { totalPages } = useAxtPagination(page, pageSize, totalItems);

  const handlePageIncrement = useCallback(
    () => onChange({ size: pageSize, page: page + 1 }),
    [pageSize, page, onChange]
  );

  const handlePageDecrement = useCallback(
    () => onChange({ size: pageSize, page: page - 1 }),
    [pageSize, page, onChange]
  );

  const handleChangeSize = useCallback(
    (event) => onChange({ size: parseInt(event.target.value, 10), page: 0 }),
    [onChange]
  );

  return (
    <Grid container className={classes.root}>
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={4}
        className={classes.panel}
        justifyContent={isScreenWiderThanMd ? 'flex-start' : 'center'}
      >
        <Typography className={classes.text}>{rangeLabel}</Typography>
        <Select
          variant="standard"
          value={pageSize}
          onChange={handleChangeSize}
          disableUnderline
        >
          {pageSizes.map((pageSize) => (
            <MenuItem key={pageSize} value={pageSize}>
              {pageSize}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={4}
        className={classes.panel}
        justifyContent="center"
      >
        <Typography>{itemsLabel}</Typography>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={4}
        className={classes.panel}
        justifyContent={isScreenWiderThanMd ? 'flex-end' : 'center'}
      >
        <Typography className={classes.text}>{pageLabel}</Typography>
        <AxtIconButton
          aria-label="previous page"
          className={classes.navigationButton}
          color="secondary"
          onClick={handlePageDecrement}
          disabled={page === 0}
          size={size}
        >
          <ArrowLeft />
        </AxtIconButton>
        <AxtIconButton
          aria-label="next page"
          className={classes.navigationButton}
          color="secondary"
          onClick={handlePageIncrement}
          disabled={page >= totalPages - 1}
          size={size}
        >
          <ArrowRight />
        </AxtIconButton>
      </Grid>
    </Grid>
  );
}

import React from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

const useStyle = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: theme.palette.grey[100],
      },
      sm: {
        padding: theme.spacing(1, 2),
      },
      lg: {
        padding: theme.spacing(2),
      },
    }),
  { name: 'AxtPageHeaderStyle' }
);

export type AxtPageHeaderProps = {
  size?: 'sm' | 'lg';
  children: React.ReactNode;
};

function AxtPageHeader({ size, children }: AxtPageHeaderProps) {
  const classes = useStyle();
  return (
    <div
      className={clsx(classes.root, size === 'sm' ? classes.sm : classes.lg)}
    >
      {children}
    </div>
  );
}

export default AxtPageHeader;

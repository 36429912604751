import { getKeycloakInstance } from '../../keycloak';

export function updateTokenOrLogout() {
  return new Promise<void>((resolve, reject) => {
    const keycloakInstance = getKeycloakInstance();
    keycloakInstance
      .updateToken(30)
      .then(() => {
        resolve();
      })
      .catch(() => {
        keycloakInstance.logout();
        reject();
      });
  });
}
